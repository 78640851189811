<div class="calendar-container page-container">
  <div>
    <button *ngIf="calendarContent == 'truckPackage'" class="orange-button" type="button" mat-button
      (click)="openCreatePackage()">
      <mat-icon>add</mat-icon>
      New Package
    </button>
  </div>
  <div class="calendar-header">
    <div class="calendar-button-left">
      <button type="button" class="today-btn" mat-button (click)="onClickToday()">
        {{'today' | translate}}
      </button>
    </div>
    <div class="calendar-title">
      {{calendarTitle}}
    </div>
    <div class="calendar-button-right">
      <mat-icon class="navigate-icon" (click)="onClickPrev()" style="cursor: pointer;">
        arrow_back_ios
      </mat-icon>
      <button type="button" style="margin: 0 15px;" class="select-day-button" mat-button>
        <div class="row flex calendar-title-details align-items-center">
          {{calendarTitleDetails}}
        </div>
      </button>
      <mat-icon class="navigate-icon" (click)="onClickNext()" style="cursor: pointer;">
        arrow_forward_ios
      </mat-icon>
    </div>
    <div class="calendar-button-right">
      <button *ngIf="calendarContent == 'driverSchedule'" type="button"
        [ngClass]="{'week-btn': true, 'active': isCalendarDayShowed}" mat-button (click)="changeCalendarType('day')">
        {{ 'day' | translate }}
      </button>
      <button type="button" [ngClass]="{'week-btn': true, 'active': isCalendarWeekShowed}" mat-button
        (click)="changeCalendarType('week')">
        {{'week' | translate}}
      </button>
      <button type="button" [ngClass]="{'month-btn': true, 'active': isCalendarMonthShowed}" mat-button
        (click)="changeCalendarType('month')">
        {{'month' | translate}}
      </button>
    </div>
  </div>
  <div class="calendar-group">
    <app-rlog-calendar-month (openDriverDetailsEmitter)="openDriverDetails($event)" [listIdsSelected]="listIdsSelected"
      calendarContent="{{calendarContent}}" #calendarMonth *ngIf="isCalendarMonthShowed">
    </app-rlog-calendar-month>
    <app-rlog-calendar-week #calendarWeek calendarContent="{{calendarContent}}" *ngIf="isCalendarWeekShowed"
      [listIdsSelected]="listIdsSelected"
      (openDriverDetailsEmitter)="openDriverDetails($event)"
      (updateWeekCalendarTitleEmitter)="updateWeekCalendarTitle()"></app-rlog-calendar-week>
    <app-rlog-calendar-day calendarContent="{{calendarContent}}" [listIdsSelected]="listIdsSelected"
      #calendarDay *ngIf="isCalendarDayShowed">
    </app-rlog-calendar-day>
  </div>
</div>