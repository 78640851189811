import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiUrl } from 'src/environments/apiUrl';
import { CreateOrUpdateHolidayDto } from '../interfaces/createOrUpdateHolidayDto';
import { CreateOrUpdateOffBreakDto, UpdateOffBreakDto } from '../interfaces/createOrUpdateOffBreakDto';
import { CreateOrUpdateScheduleDto } from '../interfaces/createOrUpdateScheduleDto';
import { ScheduleDetailDto } from '../interfaces/scheduleDetailDto';
import { ScheduleModel } from '../interfaces/scheduleDto';
import { ScheduleIdByGuid } from '../interfaces/ScheduleIdByGuid';
import { ScheduleResponeDto } from '../interfaces/scheduleResponeDto';
import { User } from '../interfaces/user';
import { DashboardStopPoint } from '../interfaces/DashboardStopPoint';
import { ListSchedulesSearchInput } from '../interfaces/ListSchedulesSearchInput';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) { }

  public createSchedule(input: CreateOrUpdateScheduleDto): Observable<boolean> {
    return this.http.post<boolean>(ApiUrl.Schedule(), input);
  }

  public updateSchedule(input: CreateOrUpdateScheduleDto, id: string): Observable<boolean> {
    return this.http.put<boolean>(ApiUrl.UpdateSchedule(id), input);
  }

  public createOffBreak(input: CreateOrUpdateOffBreakDto): Observable<boolean> {
    return this.http.post<boolean>(ApiUrl.OffBreak(), input);
  }

  public updateOffBreak(input: UpdateOffBreakDto): Observable<boolean> {
    return this.http.put<boolean>(ApiUrl.OffBreak(), input);
  }

  public createHoliday(input: CreateOrUpdateHolidayDto): Observable<boolean> {
    return this.http.post<boolean>(ApiUrl.Holiday(), input);
  }

  public getScheduleByTruckId(truckId: string): Observable<ScheduleResponeDto[]> {
    return this.http.get<ScheduleResponeDto[]>(ApiUrl.ScheduleByTruckId(truckId))
  }

  public getScheduleByDriverId(driverId: string): Observable<ScheduleResponeDto[]> {
    return this.http.get<ScheduleResponeDto[]>(ApiUrl.ScheduleByDriverId(driverId))
  }

  public getScheduleDetail(scheduleId: string): Observable<ScheduleDetailDto> {
    return this.http.get<ScheduleDetailDto>(ApiUrl.ScheduleDetail(scheduleId))
  }

  public getScheduleDetailByTruckId(truckId: string): Observable<ScheduleDetailDto[]> {
    return this.http.get<ScheduleDetailDto[]>(ApiUrl.ScheduleDetailByTruckId(truckId))
  }

  public getScheduleDetailByClientId(clientId: string): Observable<ScheduleDetailDto[]> {
    return this.http.get<ScheduleDetailDto[]>(ApiUrl.ScheduleDetailByClientId(clientId))
  }

  public getScheduleDetailByDriverId(driverId: string): Observable<ScheduleDetailDto[]> {
    return this.http.get<ScheduleDetailDto[]>(ApiUrl.ScheduleDetailByDriverId(driverId))
  }

  public getScheduleList(): Observable<ScheduleModel[]> {
    return this.http.get<ScheduleModel[]>(ApiUrl.Schedule())
  }

  public getSchedulesId(companyId: string): Observable<ScheduleIdByGuid[]> {
    return this.http.get<ScheduleIdByGuid[]>(ApiUrl.SchedulesIdByCompanyId(companyId))
  }

  public getScheduleByUserId(): Observable<ScheduleResponeDto[]> {
    let userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('rlog-manage-currentUser') as string));
    let user = userSubject.value;
    return this.http.get<ScheduleResponeDto[]>(ApiUrl.ScheduleByCompanyId(user.accountId.toString()))
  }

  public getStopPointByScheduleId(scheduleId: string): Observable<DashboardStopPoint[]> {
    return this.http.get<DashboardStopPoint[]>(ApiUrl.GetStopPointByScheduleId(scheduleId))
  }

  public searchScheduleList(input: ListSchedulesSearchInput): Observable<ScheduleDetailDto[]> {
    return this.http.get<ScheduleDetailDto[]>(ApiUrl.SearchScheduleList(input))
  }
}
