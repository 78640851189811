import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiUrl } from 'src/environments/apiUrl';
import { TruckDetailResponseDto } from '../interfaces/truckDetailResponseDto';
import { TruckListResponseDto } from '../interfaces/truckListResponseDto';
import { TruckOpenCapacityDto } from '../interfaces/TruckOpenCapacityDto';
import { TruckUpdateCreateDto } from '../interfaces/truckUpdateCreateDto';
import { User } from '../interfaces/user';
import { TruckResponseModelDto } from '../interfaces/TruckResponseModelDto';

@Injectable({
  providedIn: 'root'
})
export class TruckService {

  constructor(private http: HttpClient) { }

  public createTruck(truckModel: TruckUpdateCreateDto): Observable<boolean> {
    return this.http.post<boolean>(ApiUrl.Truck(), truckModel);
  }

  public getTruckList(): Observable<TruckListResponseDto[]> {
    let userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('rlog-manage-currentUser') as string));
    let user = userSubject.value;
    return this.http.get<TruckListResponseDto[]>(ApiUrl.TruckByUserId(user.accountId.toString()));
  }

  public getTruckOpenCapacity(scheduleId: string): Observable<TruckOpenCapacityDto> {
    return this.http.get<TruckOpenCapacityDto>(ApiUrl.TruckOpenCapacity(scheduleId));
  }

  public getTruckDetail(truckId: string): Observable<TruckDetailResponseDto> {
    return this.http.get<TruckDetailResponseDto>(ApiUrl.TruckById(truckId));
  }

  public getTruckListByCompanyId(companyId: string): Observable<TruckResponseModelDto[]> {
    return this.http.get<TruckResponseModelDto[]>(ApiUrl.TruckByCompanyId(companyId));
  }

  public updateTruckDetail(truckModel: TruckUpdateCreateDto): Observable<boolean> {
    return this.http.put<boolean>(ApiUrl.TruckById(truckModel.truckId), truckModel);
  }

  public deleteTruck(truckId: string): Observable<boolean> {
    return this.http.delete<boolean>(ApiUrl.TruckById(truckId));
  }
}
