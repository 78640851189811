<div class="calendar-month-container">
  <div class="leftside-list">
    <div class="clickable-list">
      <app-dropdown-modal *ngIf="calendarContent === 'truckSchedule' && dropdownData.length > 0"
        dropdownType="Truck" [dropdownData]="dropdownData"
        (listSelectedEmitter)="listTruckSelected($event)">
      </app-dropdown-modal>
      <div *ngFor="let item of leftItemsListFilter"
        [ngClass]="{'clickable-item': true, 'active': selectedItemId == item.id}"
        (click)="selectItem(item.id)">
        <img src="{{item.imageUrl}}" alt="" class="clickable-item-avatar">
        <div class="clickable-item-name">
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
  <div class="rightside-calendar" id="fullcalendar-month">
    <full-calendar #fullcalendar [options]="fullCalendarOptions"></full-calendar>
  </div>
</div>
