import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RlogCalendarMonthComponent } from './rlog-calendar-month/rlog-calendar-month.component';
import { RlogCalendarWeekComponent } from './rlog-calendar-week/rlog-calendar-week.component';
import { TranslateService } from '@ngx-translate/core';
import { RlogCalendarDayComponent } from './rlog-calendar-day/rlog-calendar-day.component';

@Component({
  selector: 'app-rlog-calendar',
  templateUrl: './rlog-calendar.component.html',
  styleUrls: ['./rlog-calendar.component.scss']
})
export class RlogCalendarComponent implements OnInit {
  @ViewChild('calendarMonth', { static: false })
  calendarMonth!: RlogCalendarMonthComponent;

  @ViewChild('calendarWeek', { static: false })
  calendarWeek!: RlogCalendarWeekComponent;

  @ViewChild('calendarDay', { static: false })
  calendarDay!: RlogCalendarDayComponent;

  @Output()
  openCreateScheduleEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openCreatePackageEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openCreateDriverEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openDriverDetailsEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openDriverInfoEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectedDate!: Date;

  @Input()
  calendarContent!: string;

  @Input()
  listIdsSelected!: string[];

  isCalendarMonthShowed: boolean = false;

  isCalendarWeekShowed: boolean = true;

  isCalendarDayShowed: boolean = false;

  calendarTitle: string = "";
  calendarTitleDetails: string = "";


  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.translate.use(localStorage.getItem('language') as string);
  }

  ngOnInit(): void {
  }

  public reloadLeftSide() {
    this.calendarMonth.refreshLeftSide();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedDate']) {
      if (this.isCalendarMonthShowed && this.calendarMonth) {
        this.calendarMonth.goToDate(this.selectedDate);
        this.getCalendarTitle();
      } else if (this.calendarWeek) {
        this.calendarWeek.gettWeekDateListBySelectedDate(this.selectedDate);
        this.updateWeekCalendarTitle();
      } else if (this.calendarDay && this.isCalendarDayShowed) {
        this.calendarDay.goToDate(this.selectedDate);
        this.getCalendarTitle();
      }
    }
    if (changes['listIdsSelected']) {
      if (this.isCalendarMonthShowed && this.calendarMonth) {
        this.calendarMonth.calendarDataFilter(this.listIdsSelected);
      } else if (this.calendarWeek) {
        this.calendarWeek.calendarDataFilter(this.listIdsSelected);
      } else if (this.calendarDay && this.isCalendarDayShowed) {
        this.calendarDay.calendarDataFilter(this.listIdsSelected);
      }
    }
  }

  ngAfterViewInit() {
    this.getCalendarTitle();
    this.cdr.detectChanges();
  }

  changeCalendarType(type: string) {
    switch (type) {
      case 'day':
        this.isCalendarDayShowed = true;
        this.isCalendarMonthShowed = false;
        this.isCalendarWeekShowed = false;
        // this.onClickToday();
        break;
      case 'week':
        this.isCalendarDayShowed = false;
        this.isCalendarMonthShowed = false;
        this.isCalendarWeekShowed = true;
        break;
      case 'month':
        this.isCalendarDayShowed = false;
        this.isCalendarMonthShowed = true;
        this.isCalendarWeekShowed = false;
        break;
      default:
        break;
    }
    let root = this;

    if (this.isCalendarMonthShowed) {
      setTimeout(function () {
        root.getCalendarTitle();
      });
    }

    if (this.isCalendarDayShowed) {
      setTimeout(function () {
        root.onClickToday();
      });
    }
  }

  updateWeekCalendarTitle() {
    this.calendarTitle = this.calendarWeek.currentWeekTitle;
    this.calendarTitleDetails = this.calendarWeek.currentWeekTitleDetails;
  }

  onClickPrev() {
    if (this.isCalendarMonthShowed) {
      (document.querySelector("#fullcalendar-month .fc-prev-button") as HTMLButtonElement).click();
      this.getCalendarTitle();
      return;
    }
    else if (this.isCalendarDayShowed) {
      (document.querySelector("#fullcalendar-day .fc-prev-button") as HTMLButtonElement).click();
      this.getCalendarTitle();
      return;
    }
    else {
      this.calendarWeek.prevWeek();
      this.calendarTitle = this.calendarWeek.currentWeekTitle;
      this.updateWeekCalendarTitle()
    }
  }

  onClickNext() {
    if (this.isCalendarMonthShowed) {
      (document.querySelector("#fullcalendar-month .fc-next-button") as HTMLButtonElement).click();
      this.getCalendarTitle();
      return;
    }
    else if (this.isCalendarDayShowed) {
      (document.querySelector("#fullcalendar-day .fc-next-button") as HTMLButtonElement).click();
      this.getCalendarTitle();
      return;
    }
    else {
      this.calendarWeek.nextWeek();
      this.calendarTitle = this.calendarWeek.currentWeekTitle;
      this.updateWeekCalendarTitle()
    }
  }

  onClickToday() {
    if (this.isCalendarMonthShowed) {
      (document.querySelector("#fullcalendar-month .fc-today-button") as HTMLButtonElement).click();
    }
    else if (this.isCalendarDayShowed) {
      (document.querySelector("#fullcalendar-day .fc-today-button") as HTMLButtonElement).click();
    }
    else {
      // click today
      this.calendarWeek.getCurrentWeekDateList();
      this.calendarTitle = this.calendarWeek.currentWeekTitle;
    }
    this.getCalendarTitle();
  }

  getCalendarTitle() {
    if (this.isCalendarMonthShowed) {
      if (document.querySelector("#fullcalendar-month .fc-toolbar-title")) {
        this.calendarTitle = (document.querySelector("#fullcalendar-month .fc-toolbar-title") as HTMLElement).innerText;
        this.calendarTitleDetails = (document.querySelector("#fullcalendar-month .fc-toolbar-title") as HTMLElement).innerText;
      }
    } else if (this.isCalendarDayShowed) {
      this.calendarTitle = (document.querySelector("#fullcalendar-day .fc-toolbar-title") as HTMLElement).innerText;
      this.calendarTitleDetails = (document.querySelector("#fullcalendar-day .fc-toolbar-title") as HTMLElement).innerText;
    } else { }
  }

  openCreateSchedule() {
    this.openCreateScheduleEmitter.emit();
  }

  openCreatePackage() {
    this.openCreatePackageEmitter.emit();
  }

  openCreateDriverPopup() {
    this.openCreateDriverEmitter.emit();
  }

  openDriverDetails(driverId: string) {
    this.openDriverDetailsEmitter.emit(driverId);
  }

  openDriverInfo(driverId: string) {
    this.openDriverInfoEmitter.emit(driverId);
  }
}
