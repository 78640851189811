import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiUrl } from 'src/environments/apiUrl';
import { environment } from 'src/environments/environment';
import { CreateOrUpdateDriverDto } from '../interfaces/CreateOrUpdateDriverDto';
import { DriverModel } from '../interfaces/driverDto';
import { DriverListResponseDto } from '../interfaces/driverListResponseDto';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private http: HttpClient) { }

  public createDriver(createOrUpdateDriverModel: CreateOrUpdateDriverDto): Observable<boolean> {
    return this.http.post<boolean>(ApiUrl.Driver(), createOrUpdateDriverModel);
  }

  public updateDriver(driverId: string,createOrUpdateDriverModel: CreateOrUpdateDriverDto): Observable<boolean> {
    return this.http.put<boolean>(ApiUrl.DriverById(driverId), createOrUpdateDriverModel);
  }

  public getListDriver(): Observable<DriverListResponseDto[]> {
    let userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('rlog-manage-currentUser') as string));
    let user = userSubject.value;
    return this.http.get<DriverListResponseDto[]>(ApiUrl.DriverByUserId(user.accountId.toString()));
  }

  public getListDriverByCompanyId(companyId: string): Observable<DriverModel[]> {
    return this.http.get<DriverModel[]>(ApiUrl.DriverByCompanyId(companyId));
  }

  public getDriverDetail(driverId: string): Observable<DriverModel> {
    return this.http.get<DriverModel>(ApiUrl.DriverById(driverId));
  }

  public deleteDriver(driverId: string): Observable<boolean> {
    return this.http.delete<boolean>(ApiUrl.DriverById(driverId));
  }
}
