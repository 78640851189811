import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { DriverModel } from 'src/app/shared/interfaces/driverDto';
import { ScheduleLeftItemModel } from 'src/app/shared/interfaces/scheduleLeftItemModel';
import { TruckUpdateCreateDto } from 'src/app/shared/interfaces/truckUpdateCreateDto';
import { DriverService } from 'src/app/shared/services/driver.service';
import { PackageService } from 'src/app/shared/services/package.service';
import { ScheduleService } from 'src/app/shared/services/schedule.service';
import { TruckService } from 'src/app/shared/services/truck.service';
import { formatDate } from '@angular/common';
import { TruckListResponseDto } from 'src/app/shared/interfaces/truckListResponseDto';
import { DriverListResponseDto } from 'src/app/shared/interfaces/driverListResponseDto';
import allLocales from '@fullcalendar/core/locales-all'
import { environment } from 'src/environments/environment';
import { DropdownData } from 'src/app/shared/interfaces/DropdownData';

@Component({
  selector: 'app-rlog-calendar-month',
  templateUrl: './rlog-calendar-month.component.html',
  styleUrls: ['./rlog-calendar-month.component.scss'],
})
export class RlogCalendarMonthComponent implements OnInit {
  @ViewChild('fullcalendar', { static: true })
  fullcalendar!: FullCalendarComponent;

  @Input()
  calendarContent: string = '';

  @Input()
  listIdsSelected!: string[];

  @Output()
  openDriverDetailsEmitter: EventEmitter<any> = new EventEmitter<any>();

  leftSideTruckItem: TruckListResponseDto[] = [];

  leftDriverList: DriverListResponseDto[] = [];
  leftItemsList: ScheduleLeftItemModel[] = [
    {
      id: '',
      name: '',
      imageUrl: '',
    },
  ];
  leftItemsListFilter: ScheduleLeftItemModel[] = [
    {
      id: "",
      name: "",
      imageUrl: ""
    }
  ];

  dropdownData: DropdownData[] = [];

  selectedItemId: string = '';
  previousItemId: string = '';

  eventList: any[] = [];

  fullCalendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    eventColor: '#8263f2',
    locales: allLocales,
    locale: localStorage.getItem("locale") as string,
    eventContent: function (arg) {
      let fromTitle: string;
      let toTitle: string;
      if (arg.event.title == 'off') {
        fromTitle = toTitle = arg.event.title;
      } else {
        fromTitle = arg.event.title.split('-')[0];
        toTitle = arg.event.title.split('-')[1];
      }
      let titleElem = document.createElement('div');
      titleElem.innerHTML = `<div class="fc-event-main-frame">
        <div class="fc-event-title-container">
          <div class="fc-event-title fc-event-title-left">
            ${fromTitle}
          </div>
          <div class="fc-event-title fc-event-title-right">
            ${toTitle}
          </div>
        </div>
      </div>`;
      let arrayOfDomNodes = [titleElem];
      return { domNodes: arrayOfDomNodes };
    },
    events: this.eventList,
    firstDay: 1,
  };

  constructor(
    private truckService: TruckService,
    private driverService: DriverService,
    private scheduleService: ScheduleService,
    private packageService: PackageService
  ) { }

  ngOnInit(): void {
    // this.getLeftSideList();
    if (this.calendarContent == 'driverSchedule') {
      this.loadDriverList();
    } else if (this.calendarContent == 'truckPackage') {
      this.loadTruckList();
    } else if (this.calendarContent == 'truckSchedule') {
      this.loadTruckList();
    }
    this.selectedItemId = this.leftItemsList[0].id;
    this.previousItemId = this.selectedItemId;
  }

  public refreshLeftSide() {
    this.ngOnInit();
  }

  loadTruckList() {
    this.truckService.getTruckList().subscribe({
      next: (v) => {
        this.leftSideTruckItem = v;
        var list: ScheduleLeftItemModel[] = [];
        this.leftSideTruckItem.forEach((item) => {
          list.push({
            id: item.id,
            name: item.truckName,
            imageUrl: item.imageUrl != undefined && item.imageUrl != ""
              ? environment.apiUrl + '/' + item.imageUrl
              : '/assets/images/default-truck.png',
          });
        });
        this.leftItemsList = list;
        this.leftItemsListFilter = list;
        list.forEach(item => {
          this.dropdownData.push({
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl,
            isSelected: false,
          })
        })
        this.selectedItemId = this.leftItemsList[0].id;
        if (this.calendarContent == 'truckPackage') {
        } else {
          this.loadScheduleByTruck();
        }
      },
    });
  }

  loadDriverList() {
    // this.driver
    this.driverService.getListDriver().subscribe({
      next: (v) => {
        this.leftDriverList = v;
        var list: ScheduleLeftItemModel[] = [];
        this.leftDriverList.forEach((item) => {
          list.push({
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl && item.imageUrl != ""
              ? environment.apiUrl + '/' + item.imageUrl
              : '/assets/images/default-avatar.png',
          });
        });
        this.leftItemsList = list;
        this.leftItemsListFilter = list;
        this.selectedItemId = this.leftItemsList[0].id;
        this.listIdsSelected && this.calendarDataFilter(this.listIdsSelected);
        this.loadScheduleByDriver();
      },
    });
  }

  ngAfterViewInit() {
    this.getSelectedItemEvents();

    let root = this;
    setTimeout(function () {
      root.getSelectedItemEvents();
      root.reRenderCalendar();
    });
  }

  loadScheduleByDriver() {
    if (!this.selectedItemId) return;
    this.scheduleService.getScheduleByDriverId(this.selectedItemId).subscribe({
      next: (v) => {
        let fullcalendarEvents: any[] = [];
        v.forEach((item) => {
          let scheduleDetailUrl = '/drivers/schedule-detail';
          var fromDate = new Date(item.startPoint.dateTime);
          var toDate = new Date(item.endPoint.dateTime);
          let title: string;
          if (item.scheduleType == 0) {
            title = item.startPoint.city + '-' + item.endPoint.city;
            fullcalendarEvents.push({
              id: item.id,
              title: title,
              classNames: '',
              start: `${formatDate(fromDate, 'yyyy-MM-dd', 'en-US')}`,
              end: `${formatDate(toDate, 'yyyy-MM-dd', 'en-US')}`,
              description: '',
              url: `${scheduleDetailUrl}/${item.id}`,
            });
          }
          // else {
          //   title = 'off';
          //   fullcalendarEvents.push({
          //     id: item.id,
          //     title: title,
          //     classNames: ['off-event'],
          //     start: `${formatDate(fromDate, 'yyyy-MM-dd', 'en-US')}`,
          //     end: `${formatDate(toDate, 'yyyy-MM-dd', 'en-US')}`,
          //     description: '',
          //     url: `${scheduleDetailUrl}/${item.id}`,
          //   });
          // }
        });
        this.fullCalendarOptions.events = fullcalendarEvents;
      },
    });
  }

  loadScheduleByTruck() {
    this.scheduleService.getScheduleByTruckId(this.selectedItemId).subscribe({
      next: (v) => {
        let fullcalendarEvents: any[] = [];
        let scheduleDetailUrl = '/trucks/schedule-detail/';
        var tabId = 1;
        v.forEach((item) => {
          var fromDate = new Date(item.startPoint.dateTime);
          var toDate = new Date(item.endPoint.dateTime);
          let title: string;
          if (item.scheduleType == 0) {
            title = item.startPoint.city + '-' + item.endPoint.city;
            fullcalendarEvents.push({
              id: item.id,
              title: title,
              classNames: '',
              start: `${formatDate(fromDate, 'yyyy-MM-dd', 'en-US')}`,
              end: `${formatDate(toDate, 'yyyy-MM-dd', 'en-US')}`,
              description: '',
              url: `${scheduleDetailUrl}${item.id}?id=${item.id}&menu=truck&tabId=${tabId}`,
            });
          }
          // else {
          //   title = 'off';
          //   fullcalendarEvents.push({
          //     id: item.id,
          //     title: title,
          //     classNames: ['off-event'],
          //     start: `${formatDate(fromDate, 'yyyy-MM-dd', 'en-US')}`,
          //     end: `${formatDate(toDate, 'yyyy-MM-dd', 'en-US')}`,
          //     description: '',
          //     url: `${scheduleDetailUrl}${item.id}?id=${item.id}&menu=truck&tabId=${tabId}`,
          //   });
          // }
        });
        this.fullCalendarOptions.events = fullcalendarEvents;
      },
    });
  }


  selectItem(itemId: string) {
    this.previousItemId = this.selectedItemId;
    this.selectedItemId = itemId;
    if (this.calendarContent == 'driverSchedule') {
      if (this.previousItemId == this.selectedItemId) {
        this.openDriverDetailsEmitter.emit(itemId);
      } else {
        this.loadScheduleByDriver();
      }
    }  else if (this.calendarContent == 'truckSchedule') {
      this.loadScheduleByTruck();
    }
    // this.getSelectedItemEvents();
  }

  // getLeftSideList() {
  //   // Hard code
  //   this.leftSideItemList = [
  //     {
  //       id: 1,
  //       name: "Truck 1"
  //     },
  //     {
  //       id: 2,
  //       name: "Truck 2"
  //     },
  //   ]
  //   // Hard code end
  // }

  getSelectedItemEvents() {
    // Hard code
    let events = [
      {
        title: 'Tokyo-Nakagawa',
        start: '2021-04-04',
        end: '2021-04-08',
        description: 'This is a cool event',
        id: 1,
      },
      // {
      //   title: 'Tokyo-Nakagawa',
      //   start: '2021-04-02',
      //   end: '2021-04-08',
      //   description: 'This is a cool event',
      //   id: 2
      // },
      // {
      //   title: 'Tokyo-Nakagawa',
      //   start: '2021-04-04',
      //   end: '2021-04-08',
      //   description: 'This is a cool event',
      //   id: 3
      // },
      // {
      //   title: 'Tokyo-Nakagawa',
      //   start: '2021-05-20',
      //   end: '2021-05-28',
      //   description: 'This is a cool event',
      //   id: 4
      // },
      // {
      //   title: 'Tokyo-Nakagawa',
      //   start: '2021-05-20',
      //   end: '2021-05-28',
      //   description: 'This is a cool event',
      //   id: 5
      // },
      // {
      //   title: 'off',
      //   start: '2021-04-20',
      //   end: '2021-04-28',
      //   description: 'This is a cool event',
      //   id: 6
      // }
    ];
    // Hard code end

    let fullcalendarEvents: any[] = [];
    let scheduleDetailUrl = '/trucks/schedule-detail/';

    events.map((event: any) => {
      if (event.title == 'off') {
        event.classNames = ['off-event'];
      }

      fullcalendarEvents.push({
        title: event.title,
        classNames: event.classNames,
        start: event.start,
        end: event.end,
        description: event.description,
        url: scheduleDetailUrl + event.id + '?tabId=1',
      });
    });

    // Call get event by truck/driver id here

    this.fullCalendarOptions.events = fullcalendarEvents;
  }

  reRenderCalendar() {
    this.fullcalendar.getApi().render();
  }

  goToDate(date: Date) {
    this.fullcalendar.getApi().gotoDate(date);
  }

  listTruckSelected(event: DropdownData[]) {
    const listIds = event.map(x => x.id);
    this.calendarDataFilter(listIds);
  }

  calendarDataFilter(listIds: string[]) {
    this.leftItemsListFilter = this.leftItemsList.filter(x => listIds.includes(x.id));
  }
}
