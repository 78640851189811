import { GoogleMapsAPIWrapper } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { FullCalendarModule } from "@fullcalendar/angular";
import { CreateScheduleComponent } from "./components/create-schedule/create-schedule.component";
import { RlogCalendarMonthComponent } from "./components/rlog-calendar/rlog-calendar-month/rlog-calendar-month.component";
import { RlogCalendarWeekComponent } from "./components/rlog-calendar/rlog-calendar-week/rlog-calendar-week.component";
import { RlogCalendarComponent } from "./components/rlog-calendar/rlog-calendar.component";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TruckLocationComponent } from "./components/truck-location/truck-location.component";
import { TruckLocationDetailComponent } from "./components/truck-location-detail/truck-location.component"
import { MatTimepickerModule } from "mat-timepicker";
import { MatDivider, MatDividerModule } from "@angular/material/divider";
import { ErrorModalComponent } from "../views/pages/modals/error-modal/error-modal.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { RlogCalendarDayComponent } from "./components/rlog-calendar/rlog-calendar-day/rlog-calendar-day.component";
import { DropdownModalComponent } from "../views/pages/modals/dropdown-modal/dropdown-modal.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

const classesToInclude = [
    RlogCalendarComponent,
    RlogCalendarMonthComponent,
    RlogCalendarWeekComponent,
    RlogCalendarDayComponent,
    CreateScheduleComponent,
    TruckLocationComponent,
    ErrorModalComponent,
    TruckLocationDetailComponent,
    DropdownModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        FullCalendarModule,
        MatDatepickerModule,
        MatTimepickerModule,
        MatNativeDateModule,
        MatDividerModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoader,
            deps: [HttpClient]
          }
        })
    ],
    providers: [
        GoogleMapsAPIWrapper
    ],
    declarations: classesToInclude,
    exports: classesToInclude
})
export class SharedModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
