import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal/modal.directive';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  @ViewChild('errorModal', { static: true })
  errorModal!: ModalDirective;
  @ViewChild('modalContent', { static: true }) modalContent!: ElementRef;
  message!: string;
  constructor() { }

  ngOnInit(): void {
   
  }

  onSubmit() {
    
  }

  show(_message: string) {
    this.message = _message;
    this.errorModal.show();
  }

  close() {
    this.errorModal.hide();
  }

}
