<div class="dropdown-container">
  <button mat-button [matMenuTriggerFor]="menu" class="dropdown-menu" (menuClosed)="menuClosed()">
    <div class="menu">
      <span id="select">{{dropdownTitle}}</span>
      <mat-icon class="default-icon dropdown-icon">
        keyboard_arrow_down
      </mat-icon>
    </div>
  </button>
  <mat-menu #menu="matMenu" class="dropdown-selected">
    <div class="dropdown-header" (click)="$event.stopPropagation();">
      <mat-icon class="default-icon close-icon" [ngStyle]="{'color':'#8263f2'}" (click)="closeMenu()">
        close
      </mat-icon>
      <span class="title">{{headerTitle}}</span>
    </div>
    <div class="dropdown-search" (click)="$event.stopPropagation();">
      <input type="checkbox" [checked]="isSelectedAll" (change)="onCheckboxAllChange($event)" />
      <div class="search-input">
        <input type="text" placeholder="{{ 'search' | translate }}" (input)="onSearchChange($event)" />
        <mat-icon class="default-icon search-icon" [ngStyle]="{'color':'#828282'}">
          search
        </mat-icon>
      </div>
    </div>
    <div class="dropdown-items" (click)="$event.stopPropagation();">
      <button *ngFor="let data of dropdownDataFilter, let i = index" class="dropdown-item" (click)="onItemClick(data.id)">
        <input type="checkbox" [checked]="data.isSelected" (change)="onCheckboxItemChange($event, data.id)"/>
        <div class="item">
          <img *ngIf="data.imageUrl" src={{data.imageUrl}} />
          <span>{{data.name}}</span>
        </div>
      </button>
    </div>
    <div class="dropdown-select-btn">
      <button type="button" class="btn btn-primary bottom-btn custom-btn-style-7" (click)="onSelect()">
        {{'select' | translate}}
      </button>
    </div>
  </mat-menu>
</div>