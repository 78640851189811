<div style="background-color: white; height: 100%; padding: 40px">
  <div class="container create-schedule-page">
    <form
      [formGroup]="createScheduleForm"
      class="page-form"
      *ngIf="!isConfirmBox"
    >
      <div class="title-box">
        <h1 class="form-title">{{ "create_schedule" | translate }}</h1>
      </div>

      <div class="custom-form-row-container">
        <div class="form-row">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <div class="field-wrapper">
              <div
                class="input-field-container input-field-container-clickable"
              >
                <label for="truck" class="field-wrapper-label">{{
                  "truck" | translate
                }}</label>
              </div>
            </div>
            <select
              class="form-control"
              #truck
              name="truck"
              name="truckId"
              formControlName="truckId"
            >
              <option
                [disabled]="isEdit"
                *ngFor="let item of truckList"
                value="{{ item.id }}"
              >
                {{ item.truckName }}
              </option>
            </select>
          </div>
          <div class="col-md-7">
            <div class="col-md-6">
              <div class="field-wrapper">
                <div
                  class="input-field-container input-field-container-clickable"
                >
                  <label for="driver" class="field-wrapper-label">{{
                    "driver" | translate
                  }}</label>
                </div>
              </div>
              <select
                class="form-control"
                name="driver"
                name="driverId"
                formControlName="driverId"
              >
                <option
                  [disabled]="isEdit"
                  *ngFor="let item of driverList"
                  value="{{ item.id }}"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <div class="field-wrapper">
              <div
                class="input-field-container input-field-container-clickable"
              >
                <label for="date-time" class="field-wrapper-label">{{
                  "date_time" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="col-md-6">
              <div class="field-wrapper">
                <div
                  class="input-field-container input-field-container-clickable"
                >
                  <label for="route" class="field-wrapper-label">{{
                    "route" | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          formArrayName="stopPoints"
          *ngFor="let stopPoint of getControls(); let i = index"
        >
          <div [formGroupName]="i">
            <div
              class="form-row"
              style="margin-top: 5px !important; margin-bottom: 20px"
            >
              <div class="col-md-1">
                <div class="field-wrapper">
                  <div
                    style="margin: 5px 0"
                    class="remove-icon"
                    (click)="removeStop(i)"
                  >
                    <mat-icon
                      class="material-icons-outlined"
                      style="color: #8263f2"
                      aria-hidden="false"
                      aria-label="Example home icon"
                      style="cursor: pointer"
                    >
                      remove_circle_outlined
                    </mat-icon>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="field-wrapper">
                  <div
                    class="input-field-container input-field-container-clickable"
                  >
                    <div
                      style="margin: 5px 0"
                      class="input-wrapper"
                      style="width: 150%"
                    >
                      <mat-icon
                        class="input-icon"
                        aria-hidden="false"
                        aria-label="Example home icon"
                      >
                        date_range
                      </mat-icon>
                      <input
                        (click)="fromDatePicker.open()"
                        [matDatepicker]="fromDatePicker"
                        type="text"
                        placeholder="{{ 'mmddyyyy' | translate }}"
                        formControlName="fromDate"
                      />
                      -
                      <input
                        (click)="toDatePicker.open()"
                        [matDatepicker]="toDatePicker"
                        type="text"
                        placeholder="{{ 'mmddyyyy' | translate }}"
                        formControlName="toDate"
                      />
                    </div>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-datepicker #toDatePicker></mat-datepicker>

                    <div
                      style="margin: 5px 0"
                      class="input-wrapper"
                      style="width: 150%"
                    >
                      <mat-icon
                        class="input-icon"
                        aria-hidden="false"
                        aria-label="Example home icon"
                      >
                        access_time
                      </mat-icon>
                      <input
                        class="input-time"
                        type="text"
                        inputmode="numeric"
                        (input)="onHourInputChange($event, i)"
                        placeholder="{{ 'hh' | translate }}"
                        formControlName="fromHourTime"
                      />
                      <span>:</span>
                      <input
                        class="input-time"
                        type="text"
                        inputmode="numeric"
                        (input)="onMinuteInputChange($event, i)"
                        placeholder="{{ 'mm' | translate }}"
                        formControlName="fromMinuteTime"
                        style="margin-right: 10px"
                      />
                      -
                      <input
                        class="input-time"
                        type="text"
                        inputmode="numeric"
                        style="margin-left: 10px"
                        (input)="onHourInputChange($event, i)"
                        placeholder="{{ 'hh' | translate }}"
                        formControlName="toHourTime"
                      />
                      <span>:</span>
                      <input
                        class="input-time"
                        type="text"
                        inputmode="numeric"
                        (input)="onMinuteInputChange($event, i)"
                        placeholder="{{ 'mm' | translate }}"
                        formControlName="toMinuteTime"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-7">
                <div class="col-md-4">
                  <div class="field-wrapper">
                    <div style="margin: 2px 0" class="input-wrapper">
                      <input
                        type="text"
                        placeholder="{{ 'post_code' | translate }}"
                        name="postCode"
                        formControlName="postCode"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field-wrapper">
                    <div style="margin: 2px 0" class="input-wrapper">
                      <input
                        type="text"
                        placeholder="{{ 'country' | translate }}"
                        name="country"
                        formControlName="country"
                      />
                    </div>
                  </div>
                  <div style="margin: 2px 0" class="input-wrapper">
                    <input
                      type="text"
                      placeholder="{{ 'state_province' | translate }}"
                      name="state"
                      formControlName="state"
                    />
                  </div>

                  <div style="margin: 2px 0" class="input-wrapper">
                    <input
                      type="text"
                      placeholder="{{ 'city_locality' | translate }}"
                      name="city"
                      formControlName="city"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="field-wrapper">
                    <div style="margin: 2px 0" class="input-wrapper">
                      <input
                        type="text"
                        placeholder="{{ 'street_details' | translate }}"
                        name="street"
                        formControlName="street"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="seprate-line" *ngIf="getControls().length - 1 > i">
              <mat-divider class="seprate-line-child"></mat-divider>
              <div class="field-wrapper">
                <div
                  style="margin: 5px 0"
                  class="remove-icon"
                  (click)="swapStop(i)"
                >
                  <mat-icon
                    class="material-icons-rounded"
                    style="color: #8263f2; transform: scale(1.5)"
                    aria-hidden="false"
                    aria-label="Example home icon"
                  >
                    swap_vertical_circle
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-buttons">
          <div class="modal-footer">
            <div style="width: 100vh; display: flex">
              <button
                type="button"
                style="margin-left: auto; margin-right: 0"
                class="btn btn-primary custom-btn-style-6"
                (click)="calculate()"
              >
                {{ "calculate_ETA" | translate }}
              </button>
            </div>

            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-secondary custom-btn-style-1"
              (click)="cancel()"
            >
              {{ "cancel" | translate }}
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-secondary custom-btn-style-1"
              (click)="addStop()"
            >
              {{ "add_stop" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-primary custom-btn-style-7"
              (click)="onSubmit()"
            >
              {{ (isEdit ? "update" : "register") | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-error-modal #errorModal></app-error-modal>

  <!-- <div class="page-confirm-box" *ngIf="isConfirmBox">
    <h1 class="form-title">Confirm schedule</h1>
    <table>
      <tbody>
        <tr class="info-row">
          <td class="info-label">
            <mat-icon class="info-label-icon" aria-hidden="false" aria-label="Example home icon">
              local_shipping
            </mat-icon>
            <div class="info-label-text">
              Truck
            </div>
          </td>
          <td class="info-content">
            Truck 1
          </td>
        </tr>
        <tr class="info-row">
          <td class="info-label">
            <mat-icon class="info-label-icon" aria-hidden="false" aria-label="Example home icon">
              person
            </mat-icon>
            <div class="info-label-text">
              Driver
            </div>
          </td>
          <td class="info-content">
            Driver 1
          </td>
        </tr>
        <tr class="info-row">
          <td class="info-label">
            <mat-icon class="info-label-icon" aria-hidden="false" aria-label="Example home icon">
              date_range
            </mat-icon>
            <div class="info-label-text">
              Date
            </div>
          </td>
          <td class="info-content">
            <div class="info-content-row">
              <div class="info-content-inline">2020/01/01</div>
              <div class="info-content-inline">to</div>
              <div class="info-content-inline">2020/01/02</div>
            </div>
          </td>
        </tr>
        <tr class="info-row">
          <td class="info-label">
            <mat-icon class="info-label-icon" aria-hidden="false" aria-label="Example home icon">
              edit_road
            </mat-icon>
            <div class="info-label-text">
              Route
            </div>
          </td>
          <td class="info-content">
            <div class="info-content-row">
              <div class="info-content-inline route-post-code">123</div>
              <div class="info-content-inline route-name">Tokyo</div>
            </div>
            <div class="info-content-row info-content-row-to">
              to
            </div>
            <div class="info-content-row">
              <div class="info-content-inline route-post-code">321</div>
              <div class="info-content-inline route-name">Kyoto</div>
            </div>
          </td>
        </tr>
        <tr class="info-row">
          <td class="info-label">
            <mat-icon class="info-label-icon" aria-hidden="false" aria-label="Example home icon">
              height
            </mat-icon>
            <div class="info-label-text">
              Distance
            </div>
          </td>
          <td class="info-content">
            <div class="info-content-row">
              <div class="info-content-inline">
                1000
                <span class="init">km</span>
              </div>
            </div>
          </td>
        </tr>
        <tr class="info-row">
          <td class="info-label">
            <mat-icon class="info-label-icon" aria-hidden="false" aria-label="Example home icon">
              crop
            </mat-icon>
            <div class="info-label-text">
              Size
            </div>
          </td>
          <td class="info-content">
            <div class="info-content-row">
              <div class="info-content-inline">
                1000
                <span class="init">km</span>
              </div>
              <div class="info-content-inline">
                100
                <span class="init">kg</span>
              </div>
              <div class="info-content-inline">
                10
                <span class="init">parcel</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-buttons">
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-secondary custom-btn-style-1" (click)="cancel()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary custom-btn-style-2" (click)="onSubmit()">
          Create
        </button>
      </div>
    </div>
  </div> -->
</div>
