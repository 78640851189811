import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { DriverService } from 'src/app/shared/services/driver.service';
import { PackageService } from 'src/app/shared/services/package.service';
import { ScheduleService } from 'src/app/shared/services/schedule.service';
import { TruckService } from 'src/app/shared/services/truck.service';
import { formatDate } from '@angular/common';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import { environment } from 'src/environments/environment';
import allLocales from '@fullcalendar/core/locales-all'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rlog-calendar-day',
  templateUrl: './rlog-calendar-day.component.html',
  styleUrls: ['./rlog-calendar-day.component.scss'],
})
export class RlogCalendarDayComponent implements OnInit {
  @ViewChild('fullcalendar', { static: true })
  fullcalendar!: FullCalendarComponent;

  @Input()
  calendarContent: string = '';

  @Input()
  listIdsSelected!: string[];
  
  resourcesList: any[] = [];
  resourcesListFilter: any[] = [];
  
  fullcalendarEvents: any[] = [];
  page: number = 1;
  
  currentLang!: string;
  
  diff_minutes(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  fullCalendarOptions: CalendarOptions = {
    allDaySlot: false,
    locales: allLocales,
    locale: localStorage.getItem("locale") as string,
    nowIndicator: true,
    plugins: [resourceTimeGridPlugin, scrollGridPlugin],
    initialView: 'resourceTimeGridFourDay',
    views: {
      resourceTimeGridFourDay: {
        type: 'resourceTimeGrid',
        duration: { days: 1 }
      }
    },
    dayMinWidth: 250,
    resourceLabelContent:
      function (arg) {
        let title = arg.resource.title;
        let imageUrl = arg.resource.extendedProps['imageUrl'];
        let arrayOfDomNodes: HTMLDivElement[] = [];
        let titleElem = document.createElement('div');
        titleElem.setAttribute("style", "width: 100%; height: 100px;")
        titleElem.innerHTML =
          `<div>
      <div class="form-row">
         <div class="col-md-6" style="
            text-align: end !important;
            margin-top: 5px;
            margin-bottom: 5px;
            ">
            <img src="${imageUrl}" style="height: 48px; width: 48px;" alt="">
         </div>
         <div class="col-md-6" style="
            text-align: initial !important;
            align-items: center;
            display: flex;
            ">
            <div>
               ${title}
            </div>
         </div>
      </div>
   </div>`
        arrayOfDomNodes.push(titleElem);
        return { domNodes: arrayOfDomNodes };
      },
    nowIndicatorContent: function (arg) {
      let hour = arg.date.getHours();
      let minute = arg.date.getMinutes();
      let arrayOfDomNodes: HTMLDivElement[] = [];
      let titleElem = document.createElement('div');
      titleElem.innerHTML =
        `<div class="fc-timegrid-now-indicator-container">
        <div style="color: white; font-size: 10px; margin-left: 2px;">${hour} : ${minute}</div>
      </div>`
      arrayOfDomNodes.push(titleElem);
      return { domNodes: arrayOfDomNodes };
    },
    eventContent: function (arg) {
      let stopPoints = arg.event.extendedProps['stopPoints'];
      let isOff = arg.event.extendedProps['isOff'];
      let from = new Date(arg.event.extendedProps['from']);
      let to = new Date(arg.event.extendedProps['to']);
      let arrayOfDomNodes: HTMLDivElement[] = [];
      var startNum = from.getTime();
      var endNum = to.getTime();
      var total = endNum - startNum;
      let titleElem = document.createElement('div');
      titleElem.setAttribute("style", "height: 100%;  overflow: hidden")
      if (isOff) {
        titleElem.innerHTML =
          `<div class="fc-event-main-frame h-100">
        <div class="fc-event-title-container h-100" style="
           background: white;
           color: #6928e1;
           font-size: 16px;
           font-weight: 400;
           padding: 10px;
           margin: 2px;
           /* align-items: center; */
           /* display: flex; */
           ">
           Break
        </div>
     </div>`;

        arrayOfDomNodes.push(titleElem);
      }
      else {
        if (stopPoints.length == 0) {
          titleElem.innerHTML =
            `<div class="fc-event-main-frame h-100">
          <div class="fc-event-title-container h-100">
            <div class="container h-100">
              <div class="col-md-12 h-100">
                <div class="row h-100">
                <div class="step-vertical-wrapper-no-left" style="height: 100%; width:100%">
                  <div class="indicator-line-vertical" style="height: 100%;"></div>
                  </div>
                </div>
             </div>
            </div>
          </div>
        </div>`;

          arrayOfDomNodes.push(titleElem);
        }
        else {
          let content = ``;
          let tooltip = "";
          for (let i = 0; i < stopPoints.length; i++) {
            let value = stopPoints[i];
            let valueDateTime = new Date(value.dateTime).getTime();
            var topLength;
            var bottomLength;
            topLength = (valueDateTime - startNum) / total * 100;
            startNum = valueDateTime;
            if (i == stopPoints.length - 1) {
              bottomLength = (endNum - valueDateTime) / total * 100;
              if (bottomLength == 0)
                titleElem.setAttribute("style", "height: max-content; width: 100%; padding-bottom: 50px; overflow: hidden")
            } else {
              bottomLength = 0;
            }
            let street = value.street;
            let city = value.city;
            let state = value.state;
            let country = value.country;
            let postCode = value.postCode;
            tooltip = `${postCode}, ${street}, ${city}, ${state}, ${country} /n`
            content += `
              <li style="
                  width: 100%;
                  color: #6928e1;
                  line-height: 1.3;
                  ">
                <div class="cut-text">${value.addressByLang}</div>
              </li>`
          }
          titleElem.innerHTML = `<div class="fc-event-main-frame h-100">
        <div class="fc-event-title-container h-100">
        <div class="container h-100">
        <div class="col-md-12 h-100">
        <div class="row h-100">
        <div class="step-vertical-wrapper-no-left" style="height: 100%; width:100%">
          <ul style="margin:0 0 0 10px; padding: 0 0; height:100%"
            matTooltip="lllll"
          >`
            +
            content
            +
            `
           </ul>
        </div>
        </div>
        </div>
        </div>
      </div>`;

          arrayOfDomNodes.push(titleElem);
        }
      }

      return { domNodes: arrayOfDomNodes };
    },
    eventColor: '#dacafb',
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    events: []
  };

  constructor(
    private truckService: TruckService,
    private driverService: DriverService,
    private scheduleService: ScheduleService,
    private packageService: PackageService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    var lang = localStorage.getItem("language");
    if (lang != null) {
      this.currentLang = lang;
    }

    // this.getLeftSideList();
    if (this.calendarContent == 'driverSchedule') {
      this.loadDriverList();
    }
  }

  public refreshLeftSide() {
    this.ngOnInit();
  }


  loadDriverList() {
    // this.driver
    this.driverService.getListDriver().subscribe({
      next: (v) => {
        var leftDriverList = v;
        leftDriverList.forEach((item) => {
          this.resourcesList.push({
            id: item.id,
            title: item.name,
            extendedProps: { imageUrl: item.imageUrl ? environment.apiUrl + '/' + item.imageUrl : '/assets/images/default-avatar.png', },
          });
        });
        //  var listLength = this.resourcesList.length
        this.resourcesListFilter = this.resourcesList;
        this.fullCalendarOptions.resources = this.resourcesList;
        this.listIdsSelected && this.calendarDataFilter(this.listIdsSelected);
        this.loadScheduleByDriver();
      },
    });
  }

  async loadScheduleByDriver() {
    for (let i = 0; i < this.resourcesListFilter.length; i++) {
      const resource = this.resourcesListFilter[i];
      const scheduledDrivers = await this.scheduleService.getScheduleDetailByDriverId(resource.id).toPromise();
      
      let listStopPoints: any = {};
      for (const item of scheduledDrivers) {
        if (item.scheduleType !== 0) break;
        const dateTime = new Date(item.stopPoints[0]?.dateTime).setHours(0, 0, 0);
        listStopPoints[dateTime] = item;
      }

      scheduledDrivers.forEach((item) => {
        if (item.scheduleType === 1) {
          const dateTime = new Date(item.stopPoints[0]?.dateTime).setHours(0, 0, 0);
          if (!listStopPoints[dateTime]) return;

          const stopPoints = listStopPoints[dateTime];
          const startTime = new Date(stopPoints[0]?.dateTime).valueOf();
          const endTime = new Date(stopPoints[stopPoints.length - 1]?.dateTime).valueOf();
          const breakTime = new Date(item.stopPoints[0]?.dateTime).valueOf();
          
          if (breakTime < startTime || breakTime >= endTime || item.truckId !== stopPoints.truckId) return;
        }

        item.stopPoints = item.stopPoints?.map((stp)=>{
          return {
            ...stp,
            addressByLang: this.currentLang === "en" 
              ? `${stp.postCode ? stp.postCode + "," : ""} ${stp.street ? stp.street + "," : ""} ${stp.city ? stp.city + "," : ""} ${stp.state ? stp.state + "," : ""} ${stp.country ? stp.country : ""}`
              : `${stp.postCode ? stp.postCode + "," : ""} ${stp.state ? stp.state + "," : ""} ${stp.city ? stp.city + "," : ""} ${stp.street ? stp.street + "," : ""} ${stp.country ? stp.country : ""}`
          }
        })

        var isOff = item.scheduleType !== 0;
        isOff && item.stopPoints.forEach(x => x.isBreak = true);
        let scheduleDetailUrl = '/drivers/schedule-detail/' + item.id;
        var fromDate = new Date(item.stopPoints[0]?.dateTime);
        var toDate = new Date(item.stopPoints[item.stopPoints.length - 1].dateTime);
        var diff = Math.round((toDate.getDate() - fromDate.getDate()));
        if (diff >= 1) {
          for (var i = 0; i <= diff; i++) {
            var title = isOff ? this.translateService.instant("break") : item.stopPoints[0]?.city + '-' + item.stopPoints[item.stopPoints.length - 1].city;
            var start = `${formatDate(fromDate.setSeconds(fromDate.getSeconds() + 1), 'yyyy-MM-dd HH:mm', 'en-US')}`;
            var end = `${formatDate(i == diff ? toDate : fromDate.setHours(23, 59, 59), 'yyyy-MM-dd HH:mm', 'en-US')}`;
            var stopPoints = isOff ? null : item.stopPoints.filter(x => new Date(x.dateTime).getTime() >= new Date(start).getTime() && new Date(x.dateTime).getTime() <= new Date(end).getTime());
            this.fullcalendarEvents.push({
              id: item.id,
              resourceId: resource.id,
              title: title,
              classNames: '',
              start: start,
              end: end,
              description: '',
              url: isOff ? "" : `${scheduleDetailUrl}?id=${item.id}&menu=driver&tabId=1`,
              extendedProps: { stopPoints: stopPoints, from: start, to: end, isOff: isOff, title: title },
            });
          }
        } else {
          this.fullcalendarEvents.push({
            id: item.id,
            resourceId: resource.id,
            title: title,
            classNames: '',
            start: `${formatDate(fromDate, 'yyyy-MM-dd HH:mm', 'en-US')}`,
            end: `${formatDate(toDate, 'yyyy-MM-dd HH:mm', 'en-US')}`,
            description: '',
            url: isOff ? "" : `${scheduleDetailUrl}?id=${item.id}&menu=driver&tabId=1`,
            extendedProps: { stopPoints: item.stopPoints, from: fromDate, to: toDate, isOff: isOff, title: title },
          });
        }
      });
    }
    this.fullCalendarOptions.events = this.fullcalendarEvents;
  }

  reRenderCalendar() {
    this.fullcalendar?.getApi().render();
  }

  goToDate(date: Date) {
    this.fullcalendar?.getApi().gotoDate(date);
  }

  calendarDataFilter(listIds: string[]) {
    this.resourcesListFilter = this.resourcesList.filter(x => listIds.includes(x.id));
    this.fullCalendarOptions.resources = this.resourcesListFilter;
  }
}
