import { Component, EventEmitter, OnInit, Output, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { EventInput } from '@fullcalendar/angular';
import { TranslateService } from '@ngx-translate/core';
import { DropdownData } from 'src/app/shared/interfaces/DropdownData';
import { DropdownType } from 'src/app/shared/models/Enums/DropdownType';

@Component({
  selector: 'app-dropdown-modal',
  templateUrl: './dropdown-modal.component.html',
  styleUrls: ['./dropdown-modal.component.scss']
})
export class DropdownModalComponent implements OnInit, OnChanges {
  @Input()
  dropdownType!: string;

  @Input()
  dropdownData: DropdownData[];

  @Input()
  title!: string;
  
  @Input()
  openMenuDefault: boolean = false;

  @Output()
  listSelectedEmitter: EventEmitter<DropdownData[]> = new EventEmitter<DropdownData[]>();

  @Output()
  closeMenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  dropdownDataFilter: DropdownData[];
  isSelectedAll: boolean = false;
  dropdownTitle: string = "";
  headerTitle: string = "";

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  constructor(
    private translateService: TranslateService
  ) {
    this.dropdownData = [];
    this.dropdownDataFilter = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.initDropdown();
    setTimeout(() => {
      this.openMenuDefault && this.openMenu();
    }, 0);
  }

  initDropdown = () => {
    this.setTitle();
    if (this.dropdownData) {
      this.dropdownData.forEach((data: DropdownData) => {
        if (!data.imageUrl) {
          switch(this.dropdownType) {
            case DropdownType.Truck:
              data.imageUrl = "assets/images/default-truck.png";
              break;
            case DropdownType.Driver:
              data.imageUrl = "assets/images/default-avatar.png";
              break;
            case DropdownType.Status:
              break;
            default:
              data.imageUrl = "assets/images/default-avatar.png";
              break;
          }
        }
      })
      this.dropdownDataFilter = this.dropdownData;
    }
    this.checkIsSelectedAll();
  };

  checkIsSelectedAll(){
    const selected = this.dropdownData.filter(x=>x.isSelected);
    if (selected.length == this.dropdownData.length) this.isSelectedAll = true;
  }

  setTitle() {
    switch(this.dropdownType) {
      case DropdownType.Truck:
        this.dropdownTitle = this.title ? this.title : this.translateService.instant("select_truck");
        this.headerTitle = this.translateService.instant("choose_truck");
        break;
      case DropdownType.Driver:
        this.dropdownTitle = this.title ? this.title : this.translateService.instant("select_driver");
        this.headerTitle = this.translateService.instant("choose_driver");
        break;
      case DropdownType.Status:
        this.dropdownTitle = this.title ? this.title : this.translateService.instant("status");
        this.headerTitle = this.translateService.instant("choose_status");
        break;
      default:
        break;
    }
  }

  preventDefault(event: MouseEvent) {
    event.preventDefault();
  }

  openMenu() {
    this.trigger?.openMenu();
  }

  closeMenu() {
    this.trigger?.closeMenu();
  }

  menuClosed() {
    this.closeMenuEmitter.emit(true);
  }

  onSearchChange(event: EventInput): void {  
    const searchValue = event.target.value;
    this.isSelectedAll = false;
    this.dropdownDataFilter = this.dropdownData.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase()));
  }

  onCheckboxAllChange(event: EventInput) {
    const isCheckedAll = event.target.checked;
    this.isSelectedAll = isCheckedAll;
    this.dropdownDataFilter.forEach(x => x.isSelected = isCheckedAll);
  }

  onItemClick(id: string) {
    this.isSelectedAll = false;
    this.dropdownDataFilter.filter(x => x.id === id).forEach(item => {
      const isSelectedPrev = item.isSelected;
      item.isSelected = !isSelectedPrev;
    })
    this.checkIsSelectedAll();
  }

  onCheckboxItemChange(event: EventInput, id: string) {
    const isCheckedItem = event.target.checked;
    this.isSelectedAll = false;
    this.dropdownDataFilter.filter(x => x.id === id)[0].isSelected = isCheckedItem;
  }

  onSelect() {
    this.listSelectedEmitter.emit(this.dropdownDataFilter.filter(x => x.isSelected));
  }
}
