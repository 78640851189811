import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from './shared/services/url.service';
import { filter } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rlog-management';
  previousUrl: string = "";
  currentUrl: string = "";

  constructor(private router: Router,
    private urlService: UrlService,
    public translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'package',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/ic_package.svg")
    )
    this.matIconRegistry.addSvgIcon(
      'trip',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/ic_trip.svg")
    )
    this.matIconRegistry.addSvgIcon(
      'payment',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/ic_payment.svg")
    )
    this.matIconRegistry.addSvgIcon(
      'route',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/ic_route.svg")
    )
    this.matIconRegistry.addSvgIcon(
      'cube',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/ic_cube.svg")
    )
    if (localStorage.getItem("language") == null) {
      this.translate.use('en');
      localStorage.setItem("language", 'en');
    }
    else {
      this.translate.use(localStorage.getItem("language") as string);
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });
  }
}
