<div class="calendar-week-container">
    <div class="calendar-week">
        <div class="calendar-week-row calendar-week-row-header">
            <div class="feature-cell">
                <app-dropdown-modal *ngIf="calendarContent === 'truckSchedule' && dropdownData.length > 0"
                    dropdownType="Truck" [dropdownData]="dropdownData"
                    (listSelectedEmitter)="listTruckSelected($event)">
                </app-dropdown-modal>
            </div>
            <div *ngFor="let date of dayInWeekList; let i = index"
                [ngClass]="{'date-cell': true, 'date-cell-today': checkIfToday(dateList[i])}">
                <div class="date-cell-content">
                    <div class="date-cell-date">
                        {{dateList[i].getDate()}}
                    </div>
                    <div class="date-cell-day-in-week">
                        {{date}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let itemData of leftItemsListFilter" class="calendar-week-row">
            <div class="feature-cell form-row" (click)="openInfo(itemData.id)" style="cursor: pointer;">
                <div class="clickable-item-avatar">
                    <img src="{{itemData.imageUrl}}" alt="" class="clickable-item-avatar">
                </div>
                <div class="col-md-6">
                    <div class="clickable-item-name">
                        {{itemData.name}}
                    </div>
                </div>
            </div>
            <div *ngFor="let date of dayInWeekList; let i = index"
                [ngClass]="{'date-cell': true, 'date-cell-today': checkIfToday(dateList[i])}">

            </div>
        </div>

        <div class="calendar-week-event-table">
            <ng-container *ngIf="itemDataList.size">
                <div *ngFor="let left of leftItemsListFilter" class="event-row">
                    <ng-container *ngIf="left.id && getEventData(left.id)">
                        <ng-container *ngFor="let event of getEventData(left.id)[0].events">
                            <div class="event {{'event-' + getTypeEventToRender(getInCurrentWeekPartOfEvent(event))}} {{event.title == 'off' ? 'event-off' : ''}}"
                                *ngIf="checkIfEventInCurrentWeek(event)"
                                [style.width.%]="getPercentByNumberDate(getInCurrentWeekPartOfEventLength(getInCurrentWeekPartOfEvent(event)))"
                                [style.margin-left.%]="getPercentByNumberDate(getStartDateOnWeekOfEvent(getInCurrentWeekPartOfEvent(event)))"
                                (click)="openScheduleDetail(event.id, event.title)">
                                <div class="event-track ">
                                    <div class="title-start"><p>{{getEventStartTitle(event)}}</p></div>
                                    <div class="title-end"><p>{{getEventEndTitle(event)}}</p></div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>