import { GoogleMapsAPIWrapper, MapsAPILoader, AgmMap } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeocodingLocation } from 'src/app/shared/interfaces/geocodingLocation';
import { ETA } from '../interfaces/eta';
import { ApiUrl } from 'src/environments/apiUrl';
import { LatLong } from '../interfaces/latLong';
import { StopPointDto } from '../interfaces/stopPointDto';

declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
    private gmapsApi: GoogleMapsAPIWrapper,
    private mapsAPILoader: MapsAPILoader,
    private http: HttpClient
  ) { }

  public getLocationByAddress(address: string) {

  }

  public getGeocoding(address: string): Observable<GeocodingLocation> {
    let result = this.http.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:jp&key=${environment.apiKey}`
    )
    return result;
  }

  public getETA(locationFrom: any, locationTo: any): Observable<ETA> {
    let result = this.http.get<any>(ApiUrl.GoogleMapETA(locationFrom.lat, locationFrom.lng, locationTo.lat, locationTo.lng));
    return result;
  }

  public getProvinceByPostCode(postCode: string): Observable<any> {
    return this.http.get<any>(
      `https://maps.google.com/maps/api/geocode/json?components=country:jp|postal_code:${postCode}.&sensor=false&key=${environment.apiKey}`
    );
  }

  private checkExistsCity(obj: any): boolean {
    for (let index = 0; index < obj.length; index++) {
      const element = obj[index];
      let arrElement = element.types;
      for (let indexItem = 0; indexItem < arrElement.length; indexItem++) {
        const elementItem = arrElement[indexItem];
        if (elementItem == 'administrative_area_level_1') {
          return true;
        }
      }
    }
    return false;
  }

  public async calculateDistance(latFrom: any, latTo: any): Promise<number> {
    return new Promise<number>((resolve) => {
      this.mapsAPILoader.load().then(() => {
        const mexicoCity = new google.maps.LatLng(latFrom.lat, latFrom.lng);
        const jacksonville = new google.maps.LatLng(latTo.lat, latTo.lng);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          mexicoCity,
          jacksonville
        );
        resolve(distance);
      });
    });
  }

  public getStopOverPointList2(
    fromPostCode: string,
    toPostCode: string,
    distance: number,
    callback: Function
  ) {
    distance = distance * 1000;
    combineLatest([
      this.getProvinceByPostCode(fromPostCode),
      this.getProvinceByPostCode(toPostCode),
    ]).subscribe(([dataFrom, dataTo]) => {
      if (
        !this.checkExistsCity(dataFrom.results[0].address_components) ||
        !this.checkExistsCity(dataTo.results[0].address_components)
      ) {
        callback(new Array());
        return;
      }
      let originPos = dataFrom.results[0].geometry.location;
      let destinationPos = dataTo.results[0].geometry.location;

      this.mapsAPILoader.load().then(() => {
        var directions: any = {};
        var bounds = new google.maps.LatLngBounds();

        var routes = {
          label: 'Km',
          request: {
            origin: new google.maps.LatLng(
              (originPos as any).lat,
              (originPos as any).lng
            ),
            destination: new google.maps.LatLng(
              (destinationPos as any).lat,
              (destinationPos as any).lng
            ),
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
          },
          rendering: {
            routeId: '',
            dist: 0,
          },
        };

        bounds.extend(routes.request.destination);
        bounds.extend(routes.request.origin);
        routes.rendering.routeId = 'r' + 0 + new Date().getTime();
        routes.rendering.dist = distance;

        requestRoute(routes);

        function setMarkers(ID: any) {
          var direction = directions[ID],
            renderer = direction.renderer,
            dist = renderer.dist,
            marker = renderer.marker,
            map = renderer.getMap(),
            dirs = direction.renderer.getDirections();

          for (var k in direction.sets) {
            var set = directions[ID].sets[k];
            set.visible = !!(k === dist);

            for (var m = 0; m < set.length; ++m) {
              set[m].setMap(set.visible ? map : null);
            }
          }
          if (!direction.sets[dist]) {
            if (dirs.routes.length) {
              var route: any = dirs.routes[0];
              var az = 0;
              for (var i = 0; i < route.legs.length; ++i) {
                if (route.legs[i].distance) {
                  az += route.legs[i].distance.value;
                }
              }
              dist = Math.max(dist, Math.round(az / 100));
              direction.sets[dist] = gMilestone(route, dist);
            }
          }
        }

        function requestRoute(route: any) {
          if (!(window as any).gDirSVC) {
            (window as any).gDirSVC = new google.maps.DirectionsService();
          }

          var renderer = new google.maps.DirectionsRenderer(route.rendering);
          // renderer.setMap(map);
          // renderer.setOptions({
          //   preserveViewport: true
          // })

          google.maps.event.addListener(
            renderer,
            'directions_changed',
            function () {
              if (directions[this.routeId]) {
                //remove markers
                for (var k in directions[this.routeId].sets) {
                  for (
                    var m = 0;
                    m < directions[this.routeId].sets[k].length;
                    ++m
                  ) {
                    directions[this.routeId].sets[k][m].setMap(null);
                  }
                }
              }

              directions[this.routeId] = {
                renderer: this,
                sets: {},
              };
              setMarkers(this.routeId);
            }
          );

          (window as any).gDirSVC.route(
            route.request,
            function (response: any, status: any) {
              if (status == google.maps.DirectionsStatus.OK) {
                renderer.setDirections(response);
              }
            }
          );
        }

        /**
         * creates markers along a google.maps.DirectionsRoute
         *
         * @param route object google.maps.DirectionsRoute
         * @param dist  int    interval for milestones in meters
         * @param opts  object google.maps.MarkerOptions
         * @return array Array populated with created google.maps.Marker-objects
         **/

        function gMilestone(route: any, dist: any) {
          var markers = [],
            geo = google.maps.geometry.spherical,
            path = route.overview_path,
            point = path[0],
            distance = 0,
            leg,
            overflow,
            pos: any = '';

          for (var p = 1; p < path.length; ++p) {
            leg = Math.round(geo.computeDistanceBetween(point, path[p]));
            let d1 = distance + 0;
            distance += leg;
            overflow = dist - (d1 % dist);

            if (distance >= dist && leg >= overflow) {
              if (overflow && leg >= overflow) {
                pos = geo.computeOffset(
                  point,
                  overflow,
                  geo.computeHeading(point, path[p])
                );
                // opts.position = pos;
                // markers.push(new google.maps.Marker(opts));
                markers.push(pos.toJSON());
                distance -= dist;
              }

              while (distance >= dist) {
                pos = geo.computeOffset(
                  point,
                  dist + overflow,
                  geo.computeHeading(point, path[p])
                );
                // opts.position = pos;
                // markers.push(new google.maps.Marker(opts));
                markers.push(pos.toJSON());
                distance -= dist;
              }
            }
            point = path[p];
          }

          // document.getElementById("pMsg").innerHTML = markers;
          if (markers.length == 0) {
            callback([originPos, destinationPos]);
          } else {
            callback(markers);
          }
          // for (var key in markers) {
          //   var obj = markers[key];
          //   if (markers[key].hasOwnProperty("position")) {
          //     // document.getElementById("pMsg").innerHTML += key + ":" + markers[key].getPosition().toUrlValue(6) + "<br>";
          //   }
          //   /*
          //  for (var prop in obj) {
          //     // important check that this is objects own property
          //     // not from prototype prop inherited
          //     if(obj.hasOwnProperty(prop)){
          //       alert(prop + " = " + obj[prop]);
          //     }
          //  }*/
          // }

          return markers;
        }
      });
    });
  }

  async calculateDistanceByStopPoints(stopPoints: StopPointDto[]) {
    const listLatLng: LatLong[] = [];
    let distances: { text: string, value: number }[] = [];
    for (const stopPoint of stopPoints) {
      var address = `${stopPoint.country} ${stopPoint.state} ${stopPoint.city} ${stopPoint.street}`
      await this.getGeocoding(address)
        .toPromise()
        .then((data) => {
          if (data.status != 'OK') {
            return;
          }
          listLatLng.push({
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng,
          })
        });
    }

    distances = await this.loadDistances(listLatLng);

    let total = 0;
    for (let distance of distances) {
      if (distance) {
        total += distance.value;
      }
    }
    return Number((total / 1000).toFixed(2));
  }

  async loadDistances(listLatLng: LatLong[]) {
    const promises: Promise<{ text: string; value: number }>[] = [];

    for (let i = 1; i < listLatLng.length; ++i) {
      promises.push(this.getDistance(listLatLng[i - 1], listLatLng[i]));
    }
    let distances = await Promise.all(promises);
    distances = [{ text: "0 km", value: 0 }].concat(distances);
    return distances;
  }

  private getDistance(startLatLng: LatLong, endLatLng: LatLong) {
    const from = new google.maps.LatLng(startLatLng.lat, startLatLng.lng);
    const to = new google.maps.LatLng(endLatLng.lat, endLatLng.lng);
    var service = new google.maps.DistanceMatrixService();
    const distance: Promise<{ text: string, value: number }> = new Promise((resolve, reject) => {
      service.getDistanceMatrix(
        {
          origins: [from],
          destinations: [to],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response: any, status: any) => {
          if (status == 'OK') {
            const results = response.rows[0].elements;
            const distance = results[0].distance;
            resolve(distance);
          } else {
            reject("Get distance fail")
          }
        });
    })
    return distance;
  }
}
