class DateUtils {
    private enMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    private jaMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    private monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    public getFirstDayOfWeekByDate(d: Date | string) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1);
        return new Date(d.setDate(diff));
    }

    public getNextDate(d: Date | string) {
        var tomorrow = new Date(d);
        tomorrow.setDate(new Date(d).getDate() + 1);
        return tomorrow;
    }

    public getMonthShortName(month: number) {
        return this.monthNames[month].slice(0, 3);
    }

    public getEnglishMonthFullName(month: number) {
        var lang = localStorage.getItem('language');
        switch (lang) {
            case 'en':
                return this.enMonthNames[month]
            case 'jp':
                return this.jaMonthNames[month]
        }
        return this.monthNames[month];
    }

    public getJapaneseMonthFullName(month: number) {
        var lang = localStorage.getItem('language');
        switch (lang) {
            case 'en':
                return this.enMonthNames[month]
            case 'jp':
                return this.jaMonthNames[month]
        }
        return this.monthNames[month];
    }

    public checkIfToday(d: Date | string) {
        let checkDate = new Date(d);
        let today = new Date();

        return (checkDate.getFullYear() == today.getFullYear()
            && checkDate.getMonth() == today.getMonth()
            && checkDate.getDate() == today.getDate());
    }

    public datediff(first: Date, second: Date) {
        return Math.round((second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24));
    }
}

export const dateUtils = new DateUtils();