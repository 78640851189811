import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'truck-location',
  templateUrl: './truck-location.component.html',
  styleUrls: ["./truck-location.component.scss"]
})
export class TruckLocationComponent {

  @Input()
  number!: number;

  @Input()
  carNumber!: string;

}
