import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { ApiUrl } from 'src/environments/apiUrl';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        private http: HttpClient
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('rlog-manage-currentUser') as string));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(userName: string, password: string) {
        return this.http.post<any>(ApiUrl.Login(), { "userName": userName, "password": password })
            .pipe(map(userToken => {
                // login successful if there's a jwt token in the response
                if (userToken) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('rlog-manage-currentUser', JSON.stringify(userToken));
                    this.currentUserSubject.next(userToken);
                }

                return userToken;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('rlog-manage-currentUser');
        this.currentUserSubject.next({} as User);
    }
}
