import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'truck-location-detail',
  templateUrl: './truck-location.component.html',
  styleUrls: ['./truck-location.component.scss']
})
export class TruckLocationDetailComponent {

  @Input()
  number!: number;

  @Input()
  carNumber!: string;

  @Input()
  driver!: string;

  @Input()
  distance!: string;

  @Input()
  contactNumber!: string;
}
