import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiUrl } from 'src/environments/apiUrl';
import { environment } from 'src/environments/environment';
import { createOrUpdatePackageDto } from '../interfaces/createOrUpdatePackageDto';
import { PackageDetailDto } from '../models/package-detail-dto';
import { ListPackageDetailModel } from '../models/package-detail-model';
import { PackageForManager } from '../models/package-for-manager';
import { PackageModel } from '../models/package-model';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  constructor(private http: HttpClient) {
  }

  public createPackage(createOrUpdatePackageDto: createOrUpdatePackageDto): Observable<any> {
    return this.http.post<boolean>(ApiUrl.Package(), createOrUpdatePackageDto);
  }

  public updatePackage(createOrUpdatePackageDto: createOrUpdatePackageDto, packageId: string): Observable<boolean> {
    return this.http.put<boolean>(ApiUrl.PackageById(packageId), createOrUpdatePackageDto);
  }

  public deletePackage(packageId: string): Observable<boolean> {
    return this.http.delete<boolean>(ApiUrl.PackageById(packageId));
  }

  public getPackageDetail(packageGuid: string): Observable<PackageDetailDto> {
    return this.http.get<PackageDetailDto>(ApiUrl.PackageById(packageGuid));
  }

  public getPackageListBySchedule(scheduleGuid: string, dateTime: string | null): Observable<PackageForManager[]> {
    return this.http.get<PackageForManager[]>(ApiUrl.PackageBySchedule(scheduleGuid, dateTime));
  }

  public getPackageListByScheduleId(scheduleGuid: string): Observable<PackageDetailDto[]> {
    return this.http.get<PackageDetailDto[]>(ApiUrl.PackageByScheduleId(scheduleGuid));
  }

  public getPackageListByUserId(): Observable<PackageDetailDto[]> {
    let userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('rlog-manage-currentUser') as string));
    let user = userSubject.value;
    return this.http.get<PackageDetailDto[]>(ApiUrl.PackageByUserId(user.accountId.toString()));
  }
}
