import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { dateUtils } from 'src/app/shared/helpers/dateUtils';
import { DropdownData } from 'src/app/shared/interfaces/DropdownData';
import { DriverListResponseDto } from 'src/app/shared/interfaces/driverListResponseDto';
import { ScheduleLeftItemModel } from 'src/app/shared/interfaces/scheduleLeftItemModel';
import { TruckListResponseDto } from 'src/app/shared/interfaces/truckListResponseDto';
import { DriverService } from 'src/app/shared/services/driver.service';
import { ScheduleService } from 'src/app/shared/services/schedule.service';
import { TruckService } from 'src/app/shared/services/truck.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rlog-calendar-week',
  templateUrl: './rlog-calendar-week.component.html',
  styleUrls: ['./rlog-calendar-week.component.scss']
})
export class RlogCalendarWeekComponent implements OnInit {

  @Output()
  updateWeekCalendarTitleEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openDriverDetailsEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  calendarContent: string = "";

  @Input()
  listIdsSelected!: string[];

  dropdownData: DropdownData[] = [];

  constructor(
    private router: Router,
    private truckService: TruckService,
    private driverService: DriverService,
    private scheduleService: ScheduleService
  ) { }

  startDateOfSelectedWeek!: Date;
  enDayInWeekList: any[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  jaDayInWeekList: any[] = ["月", "火", "水", "木", "金", "土", "日"];
  dayInWeekList: any[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  dayInWeekListFull: any[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  dateList: Date[] = [];
  leftItemsList: ScheduleLeftItemModel[] = [
    {
      id: "",
      name: "",
      imageUrl: ""
    }
  ];
  leftItemsListFilter: ScheduleLeftItemModel[] = [
    {
      id: "",
      name: "",
      imageUrl: ""
    }
  ];
  leftDriverList: DriverListResponseDto[] = []
  leftSideTruckItem: TruckListResponseDto[] = [];
  itemDataList: Map<string, any> = new Map();
  selectedItemId: string = "";
  ngOnInit(): void {
    var lang = localStorage.getItem("language");
    switch (lang) {
      case 'en':
        this.dayInWeekList = this.enDayInWeekList;
        break;
      case 'jp':
        this.dayInWeekList = this.jaDayInWeekList;
        break;
    }

    if (this.calendarContent == "driverSchedule") {
      this.loadDriverList();
    } else if (this.calendarContent == "truckPackage") {
      this.loadTruckList();
    } else if (this.calendarContent == "truckSchedule") {
      this.loadTruckList();
    }
    this.selectedItemId = this.leftItemsList[0].id;

    this.getCurrentWeekDateList();
  }

  loadTruckList() {
    this.truckService.getTruckList().subscribe({
      next: v => {
        this.leftSideTruckItem = v;
        var list: ScheduleLeftItemModel[] = [];
        this.leftSideTruckItem.forEach(item => {
          list.push({
            id: item.id,
            name: item.truckName,
            imageUrl: item.imageUrl != undefined && item.imageUrl != ""
              ? environment.apiUrl + '/' + item.imageUrl
              : '/assets/images/default-truck.png',
          });
          this.loadScheduleByTruck(item.id);
        });
        this.leftItemsList = list;
        this.leftItemsListFilter = list;
        list.forEach(item => {
          this.dropdownData.push({
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl,
            isSelected: false,
          })
        })
      }
    });
  }

  loadDriverList() {
    this.driverService.getListDriver().subscribe({
      next: v => {
        this.leftDriverList = v;
        var list: ScheduleLeftItemModel[] = [];
        this.leftDriverList.forEach(item => {
          list.push({
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl && item.imageUrl != ""
              ? environment.apiUrl + '/' + item.imageUrl
              : '/assets/images/default-avatar.png',
          });
          this.loadScheduleByDriver(item.id);
        });
        this.leftItemsList = list;
        this.leftItemsListFilter = list;
        this.listIdsSelected && this.calendarDataFilter(this.listIdsSelected);
      }
    });
  }

  loadScheduleByDriver(id: string) {
    if (!id) return;
    this.scheduleService.getScheduleByDriverId(id).subscribe({
      next: v => {
        let fullcalendarEvents: any[] = [];
        let events: any[] = [];

        v.forEach(item => {
          if (item.scheduleType !== 0) {
            return;
          }

          var fromDate = new Date(item.startPoint.dateTime);
          var toDate = new Date(item.endPoint.dateTime);
          const title = item.startPoint.city + '-' + item.endPoint.city;
          // if (item.scheduleType == 1 || item.scheduleType == 2) {
          //   title = 'off';
          // }
          // else {
          //   title = item.startPoint.city + '-' + item.endPoint.city;
          // }
          events.push({
            id: item.id,
            title: title,
            start: `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`,
            end: `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`,
            description: ''
          });
        });
        fullcalendarEvents.push({
          events: events,
        });
        this.itemDataList.set(id, fullcalendarEvents);
      }
    });
  }

  loadScheduleByTruck(id: string) {
    this.scheduleService.getScheduleByTruckId(id).subscribe({
      next: v => {
        let fullcalendarEvents: any[] = [];
        let events: any[] = [];

        v.forEach(item => {
          if (item.scheduleType !== 0) {
            return;
          }

          var fromDate = new Date(item.startPoint.dateTime);
          var toDate = new Date(item.endPoint.dateTime);
          const title = item.startPoint.city + '-' + item.endPoint.city;
          // if (item.scheduleType == 1 || item.scheduleType == 2) {
          //   title = 'off';
          // }
          // else {
          //   title = item.startPoint.city + '-' + item.endPoint.city;
          // }
          events.push({
            id: item.id,
            title: title,
            start: `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`,
            end: `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`,
            description: ''
          });
        });
        fullcalendarEvents.push({
          events: events,
        });
        this.itemDataList.set(id, fullcalendarEvents);
      }
    });
  }

  getDateTitle(dateTime: Date): string {
    if (this.dateList.length < 7) return "";
    let title: string = '';
    var lang = localStorage.getItem('language');
    switch (lang) {
      case 'en':
        title = this.dayInWeekListFull[dateTime.getDay() + 1] + ', ' + dateUtils.getMonthShortName(dateTime.getMonth()) + ' ' + dateTime.getDate() + ', ' + this.dateList[0].getFullYear();
        break;
      case 'jp':
        title = dateTime.getFullYear() + "年 " + (dateTime.getMonth() + 1) + "月";
        break;
    }
    return title;
  }

  getDateList() {
    let temp = this.startDateOfSelectedWeek;
    let index = 0;

    while (index < 7) {
      this.dateList[index] = temp;
      temp = dateUtils.getNextDate(temp);
      index++;
    }
  }

  ngAfterViewInit() {
    let root = this;
    setTimeout(function () {
      root.updateWeekCalendarTitleEmitter.emit();
    })
  }

  get currentWeekTitle() {
    if (this.dateList.length < 7) return "";
    let title: string = '';
    var lang = localStorage.getItem('language');
    switch (lang) {
      case 'en':
        title = this.dayInWeekListFull[this.dateList[0].getDay() + 1] + ', ' + dateUtils.getMonthShortName(this.dateList[0].getMonth()) + ' ' + this.dateList[0].getDate() + ', ' + this.dateList[0].getFullYear();
        break;
      case 'jp':
        title = this.dateList[0].getFullYear() + "年 " + (this.dateList[0].getMonth() + 1) + "月";
        break;
    }
    return title;
  }

  get currentWeekTitleDetails() {
    if (this.dateList.length < 7) return "";
    let title: string = '';
    var lang = localStorage.getItem('language');
    switch (lang) {
      case 'en':
        title = this.dayInWeekListFull[this.dateList[0].getDay() + 1] + ', ' + dateUtils.getMonthShortName(this.dateList[0].getMonth()) + ' ' + this.dateList[0].getDate() + ', ' + this.dateList[0].getFullYear();
        break;
      case 'jp':
        title = this.dateList[0].getFullYear() + "/" + (this.dateList[0].getMonth() + 1) + "/" + (this.dateList[0].getDate());
        break;
    }
    return title;
  }

  getCurrentWeekDateList() {
    var now = new Date();
    var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.startDateOfSelectedWeek = dateUtils.getFirstDayOfWeekByDate(startOfDay);
    this.getDateList();
  }

  gettWeekDateListBySelectedDate(selectedDate: string | Date) {
    selectedDate = new Date(selectedDate);
    this.startDateOfSelectedWeek = dateUtils.getFirstDayOfWeekByDate(selectedDate);
    this.getDateList();
  }

  prevWeek() {
    this.startDateOfSelectedWeek = dateUtils.getFirstDayOfWeekByDate(new Date(this.startDateOfSelectedWeek.getTime() - 1 * 24 * 60 * 60 * 1000));
    this.getDateList();
  }

  nextWeek() {
    this.startDateOfSelectedWeek = dateUtils.getFirstDayOfWeekByDate(new Date(this.startDateOfSelectedWeek.getTime() + 8 * 24 * 60 * 60 * 1000));
    this.getDateList();
  }

  checkIfToday(d: Date | string) {
    return dateUtils.checkIfToday(d);
  }

  checkIfEventInCurrentWeek(event: any) {
    return new Date(event.start) <= this.dateList[6] && new Date(event.end) >= this.dateList[0];
  }

  getTypeEventToRender(event: any) {
    let type = "";
    if (new Date(event.realStart) < this.dateList[0] && new Date(event.realEnd) > this.dateList[6])
      return "over";
    if (new Date(event.realStart) >= this.dateList[0] && new Date(event.realEnd) <= this.dateList[6])
      return "full";
    if (new Date(event.realStart) < this.dateList[0] && new Date(event.realEnd) >= this.dateList[0])
      type += "end";
    if (new Date(event.realStart) <= this.dateList[6] && new Date(event.realEnd) > this.dateList[6])
      type += "start";
    return type;
  }

  getInCurrentWeekPartOfEvent(event: any) {
    return {
      title: event.title,
      start: (new Date(event.start) >= this.dateList[0]) ? new Date(event.start) : this.dateList[0],
      end: (new Date(event.end) <= this.dateList[6]) ? new Date(event.end) : this.dateList[6],
      description: event.description,
      realStart: event.start,
      realEnd: event.end
    }
  }

  getInCurrentWeekPartOfEventLength(event: any) {
    return dateUtils.datediff(new Date(event.start), new Date(event.end)) + 1;
  }

  getPercentByNumberDate(dayNumber: number) {
    return 100 * dayNumber / 7;
  }

  getStartDateOnWeekOfEvent(event: any) {
    let firstDateOnWeek = dateUtils.getFirstDayOfWeekByDate(event.start);
    return dateUtils.datediff(firstDateOnWeek, new Date(event.start));
  }

  getEventStartTitle(event: any) {
    if (event.title == "off") {
      return "off";
    }

    else {
      return event.title.split('-')[0];
    }
  }

  getEventEndTitle(event: any) {
    if (event.title == "off") {
      return "off";
    }
    else {
      return event.title.split('-')[1];
    }
  }

  openScheduleDetail(eventId: string, eventTitle?: string) {
    switch (this.calendarContent) {
      case 'truckSchedule':
        this.router.navigate(['trucks/schedule-detail', eventId], {
          queryParams: {
            tabId: 1
          }
        });
        break;
      case 'driverSchedule':
        // if (eventTitle && eventTitle === 'off')
        //   return;
        this.router.navigate(['drivers/schedule-detail', eventId], {

        });
        break;
      default:
        break;
    }
  }

  openInfo(driverId: string): void {
    if (this.calendarContent == "driverSchedule") {
      this.openDriverDetailsEmitter.emit(driverId)
    }
  }


  getEventData(id: string) {
    return this.itemDataList.get(id);
  }

  listTruckSelected(event: DropdownData[]) {
    const listIds = event.map(x => x.id);
    this.calendarDataFilter(listIds);
  }

  calendarDataFilter(listIds: string[]) {
    this.leftItemsListFilter = this.leftItemsList.filter(x => listIds.includes(x.id));
    this.selectedItemId = this.leftItemsListFilter[0]?.id;
  }
}
