import { ListSchedulesSearchInput } from 'src/app/shared/interfaces/ListSchedulesSearchInput';
import { environment } from './environment';

export class ApiUrl {
  private static getUrl(enpoint: string) {
    return `${environment.apiUrl}/api/${enpoint}`;
  }

  static Login(): string {
    return this.getUrl('login');
  }

  static Driver(): string {
    return this.getUrl('driver');
  }

  static DriverById(id: string): string {
    return this.getUrl(`driver/${id}`);
  }

  static DriverByUserId(userId: string): string {
    return this.getUrl(`drivers-by-user-id/${userId}`);
  }

  static DriverByCompanyId(companyId: string): string {
    return this.getUrl(`driver/driver-list-by-company-id/${companyId}`);
  }

  static Truck(): string {
    return this.getUrl(`truck`);
  }

  static TruckByUserId(userId: string): string {
    return this.getUrl(`trucks-by-user-id/${userId}`);
  }

  static TruckOpenCapacity(scheduleId: string): string {
    return this.getUrl(`truck/open-capacity/${scheduleId}`);
  }

  static TruckById(id: string): string {
    return this.getUrl(`truck/${id}`);
  }

  static TruckByCompanyId(id: string): string {
    return this.getUrl(`truck/truck-list-by-company-id/${id}`);
  }

  static Report(): string {
    return this.getUrl(`report`);
  }

  static ReportDetail(id: string): string {
    return this.getUrl(`report/${id}`);
  }

  static Image(): string {
    return this.getUrl('image');
  }

  static Package(): string {
    return this.getUrl('packages');
  }

  static PackageById(id: string): string {
    return this.getUrl(`packages/${id}`);
  }

  static PackageBySchedule(
    scheduleId: string,
    dateTime: string | null
  ): string {
    return this.getUrl(`packages/schedule/${scheduleId}/${dateTime}`);
  }

  static PackageByScheduleId(scheduleId: string): string {
    return this.getUrl(`packages/detail/schedule/${scheduleId}`);
  }

  static PackageByUserId(id: string): string {
    return this.getUrl(`packages/user-id/${id}`);
  }

  static Schedule(): string {
    return this.getUrl('schedule');
  }

  static UpdateSchedule(id: string): string {
    return this.getUrl(`schedule/${id}`);
  }

  static GetStopPointByScheduleId(scheduleId: string): string {
    return this.getUrl(`schedule/stop-points/${scheduleId}`);
  }

  static SchedulesIdByCompanyId(companyId: string): string {
    return this.getUrl(`schedules/id/${companyId}`);
  }

  static OffBreak(): string {
    return this.getUrl('offBreak');
  }

  static Holiday(): string {
    return this.getUrl('holiday');
  }

  static ScheduleById(id: string): string {
    return this.getUrl(`schedule/${id}`);
  }

  static ScheduleByTruckId(id: string): string {
    return this.getUrl(`schedule/trucks/${id}`);
  }

  static ScheduleDetail(id: string): string {
    return this.getUrl(`schedule/${id}`);
  }

  static ScheduleDetailByTruckId(id: string): string {
    return this.getUrl(`schedule-detail/trucks/${id}`);
  }

  static ScheduleDetailByClientId(id: string): string {
    return this.getUrl(`schedule/detail/customer/${id}`);
  }

  static ScheduleByDriverId(id: string): string {
    return this.getUrl(`schedule/drivers/${id}`);
  }

  static ScheduleDetailByDriverId(id: string): string {
    return this.getUrl(`schedule-detail/drivers/${id}`);
  }

  static ScheduleByCompanyId(id: string): string {
    return this.getUrl(`schedules/user-id/${id}`);
  }

  static Tracking(companyId: string): string {
    return this.getUrl(`tracking/${companyId}`);
  }

  static TrackingByDriverId(driverId: string): string {
    return this.getUrl(`tracking/${driverId}`);
  }

  static Export(timeZone: string): string {
    return this.getUrl(`export?timeZone=${timeZone}`);
  }

  static Client(): string {
    return this.getUrl(`customer`);
  }

  static ClientsByCompanyId(companyId: string): string {
    return this.getUrl(`customers-by-company-id/${companyId}`);
  }

  static ClientById(clientId: string): string {
    return this.getUrl(`customer/${clientId}`);
  }

  static GoogleMapETA(
    latFrom: number,
    lngFrom: number,
    latTo: number,
    lngTo: number
  ) {
    return this.getUrl(`eta/${latFrom}/${lngFrom}/${latTo}/${lngTo}`);
  }

  static SearchScheduleList(input: ListSchedulesSearchInput): string {
    const queryParams = new URLSearchParams({
      PickUpAddress: input.pickUpAddress,
      DropOffAddress: input.dropOffAddress,
      PickUpDateFrom: input.pickUpDateFrom.toISOString(),
      PickUpDateTo: input.pickUpDateTo.toISOString(),
      DropOffDateFrom: input.dropOffDateFrom.toISOString(),
      DropOffDateTo: input.dropOffDateTo.toISOString(),
      Length: input.length?.toString() || '',
      Width: input.width?.toString() || '',
      Height: input.height?.toString() || '',
      Weight: input.weight?.toString() || '',
      PackageType: input.packageType?.toString() || '',
      SortBy: input.sortBy,
    });

    return `${this.getUrl('schedule/search')}?${queryParams.toString()}`;
  }
}
