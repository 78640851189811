import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { BookingPackageType } from '../../models/Enums/BookingPackageType';
import { DriverService } from '../../services/driver.service';
import { TruckService } from '../../services/truck.service';
import { MapService } from '../../services/map.service';
import { TruckListResponseDto } from '../../interfaces/truckListResponseDto';
import { DriverListResponseDto } from '../../interfaces/driverListResponseDto';
import { CreateOrUpdateScheduleDto } from '../../interfaces/createOrUpdateScheduleDto';
import { ScheduleService } from '../../services/schedule.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../interfaces/user';
import { StopPointDto } from '../../interfaces/stopPointDto';
import { ErrorModalComponent } from 'src/app/views/pages/modals/error-modal/error-modal.component';
import { LatLong } from '../../interfaces/latLong';
import { GeocodingLocation } from '../../interfaces/geocodingLocation';
import { TranslateService } from '@ngx-translate/core';
import * as signalR from '@microsoft/signalr';

@Component({
  selector: 'app-create-schedule',
  templateUrl: './create-schedule.component.html',
  styleUrls: ['./create-schedule.component.scss'],
})
export class CreateScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('formDirective', { static: true })
  formDirective!: FormGroupDirective;

  @ViewChild('errorModal', { static: true })
  errorModal!: ErrorModalComponent;

  @Input()
  isEdit: boolean = false;

  @Input()
  selectedScheduleId!: string;

  @Input()
  updateScheduleItem!: CreateOrUpdateScheduleDto;

  @Output()
  cancelCreateScheduleEmitter: EventEmitter<any> = new EventEmitter<any>();

  isConfirmBox: boolean = false;

  createScheduleForm!: FormGroup;
  stopPoints!: FormArray;
  truckList: TruckListResponseDto[] = [];
  driverList: DriverListResponseDto[] = [];
  defaultSize: string = '';
  selectedSize = BookingPackageType.Parcel;
  bookingPackageType: any;
  submitted = false;
  minEndDate: any;
  invalidTime = false;

  connection: signalR.HubConnection | undefined;

  constructor(
    private truckService: TruckService,
    private driverServivce: DriverService,
    private mapService: MapService,
    private formBuilder: FormBuilder,
    private scheduleService: ScheduleService,
    private router: Router,
    private translateService: TranslateService
  ) {}
  ngOnDestroy(): void {
    if (!this.connection) return;
    const driverId = this.createScheduleForm.value.driverId;
    this.connection.send('leaveGroup', driverId);
  }

  ngOnInit(): void {
    this.createScheduleForm = new FormGroup({
      truckId: new FormControl(''),
      driverId: new FormControl(''),
      stopPoints: new FormArray([]),
    });

    this.loadTruckData();
    this.loadDriver();
    if (this.isEdit) {
      this.createScheduleForm
        .get('truckId')
        ?.setValue(this.updateScheduleItem.truckId);
      this.createScheduleForm
        .get('driverId')
        ?.setValue(this.updateScheduleItem.driverId);

      if (
        this.updateScheduleItem.stopPoints &&
        this.updateScheduleItem.stopPoints.length
      ) {
        this.stopPoints = this.createScheduleForm.get(
          'stopPoints'
        ) as FormArray;
        this.initStopPointsForm();

        return;
      }
    }
    this.addStop();
    this.addStop();
    this.connectCreatingScheduleHub();
  }

  connectCreatingScheduleHub() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl('/api/hub/schedule-creating')
      .build();

    this.connection.on(`Send`, (data) => {
      console.log({ data });
    });

    this.connection.on(`ReceivedMessage`, (receivedData) => {
      console.log({ receivedData });
    });

    this.connection.start().catch((err) => console.log(err));
  }

  sendMessage() {
    if (!this.connection) return;
    const driverId = this.createScheduleForm.value.driverId;
    console.log({ driverId });
    this.connection.send('joinGroup', driverId);
    this.connection.send('sendMessage', driverId);
  }

  loadTruckData() {
    this.truckService.getTruckList().subscribe({
      next: (v) => {
        this.truckList = v;
      },
    });
  }

  loadDriver() {
    this.driverServivce.getListDriver().subscribe({
      next: (v) => {
        this.driverList = v;
      },
    });
  }

  onHourInputChange(event: Event, index: number) {
    const inputValue = (event.target as HTMLInputElement).value;
    const hourValue = parseInt(inputValue, 10);
    this.stopPoints.controls[index].patchValue({
      hourTime: isNaN(hourValue) ? '00' : this.formatHourMinute(hourValue % 24),
    });
  }

  onMinuteInputChange(event: Event, index: number) {
    const inputValue = (event.target as HTMLInputElement).value;
    const minuteValue = parseInt(inputValue, 10);
    this.stopPoints.controls[index].patchValue({
      minuteTime: isNaN(minuteValue)
        ? '00'
        : this.formatHourMinute(minuteValue % 60),
    });
  }

  formatHourMinute(time: number) {
    return time < 10 ? '0' + time : time;
  }

  async onSubmit() {
    this.submitted = true;
    var schedule: CreateOrUpdateScheduleDto = this.convertFromToSchedule();
    if (this.isEdit) {
      return await this.editSchedule(schedule);
    }

    await this.createSchedule(schedule);
  }

  async editSchedule(schedule: CreateOrUpdateScheduleDto) {
    try {
      this.scheduleService
        .updateSchedule(schedule, this.selectedScheduleId)
        .subscribe({
          next: (v) => {
            Swal.fire(
              this.translateService.instant('success'),
              this.translateService.instant('update_schedule_successfully'),
              'success'
            );
            window.location.reload();
          },
          error: (e) => {
            if (
              e?.error?.message?.includes('key_truck_already_scheduled_error')
            ) {
              this.errorModal.show(
                this.translateService.instant(
                  'the_truck_is_not_available_on_selected_date'
                )
              );
            } else if (
              e?.error?.message?.includes('key_driver_already_scheduled_error')
            ) {
              this.errorModal.show(
                this.translateService.instant(
                  'the_driver_is_not_available_on_selected_date'
                )
              );
            } else {
              this.errorModal.show(
                this.translateService.instant(
                  'wrong_input_data_please_review_your_input_first'
                )
              );
            }
          },
        });
    } catch {
      this.errorModal.show(
        this.translateService.instant(
          'wrong_input_data_please_review_your_input_first'
        )
      );
    }
  }

  async createSchedule(schedule: CreateOrUpdateScheduleDto) {
    try {
      this.scheduleService.createSchedule(schedule).subscribe({
        next: (v) => {
          Swal.fire(
            this.translateService.instant('success'),
            this.translateService.instant('create_schedule_successfully'),
            'success'
          ).then(() => {
            window.location.reload();
          });
          this.sendMessage();
        },
        error: (e) => {
          if (
            e?.error?.message?.includes('key_truck_already_scheduled_error')
          ) {
            this.errorModal.show(
              'The truck is not available on selected date.'
            );
          } else if (
            e?.error?.message?.includes('key_driver_already_scheduled_error')
          ) {
            this.errorModal.show(
              this.translateService.instant(
                'the_driver_is_not_available_on_selected_date'
              )
            );
          } else {
            this.errorModal.show(
              this.translateService.instant(
                'wrong_input_data_please_review_your_input_first'
              )
            );
          }
        },
      });
    } catch {
      this.errorModal.show(
        this.translateService.instant(
          'wrong_input_data_please_review_your_input_first'
        )
      );
    }
  }

  private convertFromToSchedule() {
    let stopPointsList: StopPointDto[] = [];
    this.stopPoints = this.createScheduleForm.get('stopPoints') as FormArray;
    this.stopPoints.value.forEach((stopPoint: any) => {
      let fromDateTime = new Date(stopPoint.fromDate);
      fromDateTime.setHours(stopPoint.fromHourTime);
      fromDateTime.setMinutes(stopPoint.fromMinuteTime);
      let toDateTime = new Date(stopPoint.toDate);
      toDateTime.setHours(stopPoint.toHourTime);
      toDateTime.setMinutes(stopPoint.toMinuteTime);
      stopPointsList.push({
        id: stopPoint.id,
        postCode: stopPoint.postCode,
        country: stopPoint.country,
        city: stopPoint.city,
        dateTime: fromDateTime,
        fromDateTime: fromDateTime,
        toDateTime: toDateTime,
        state: stopPoint.state,
        street: stopPoint.street,
      });
    });

    let userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('rlog-manage-currentUser') as string)
    );
    let user = userSubject.value;

    let schedule: CreateOrUpdateScheduleDto = {
      companyId: user.companyId,
      truckId: this.createScheduleForm.value.truckId,
      driverId: this.createScheduleForm.value.driverId,
      stopPoints: stopPointsList,
    };

    return schedule;
  }

  showConfirmBox() {
    this.isConfirmBox = true;
  }

  cancel() {
    this.cancelCreateScheduleEmitter.emit();
  }

  addStop() {
    this.stopPoints = this.createScheduleForm.get('stopPoints') as FormArray;
    this.stopPoints.push(this.createStopPoint());
  }

  async calculate() {
    this.stopPoints = this.createScheduleForm.get('stopPoints') as FormArray;

    if (!this.checkETAValidate()) {
      return;
    }

    for (let i = 1; i < this.stopPoints.value.length; i++) {
      let preStopPointValue = this.stopPoints.controls[i - 1].value;
      let preAddress = `${preStopPointValue.country} ${preStopPointValue.state} ${preStopPointValue.city} ${preStopPointValue.street}`;
      let preResult!: GeocodingLocation;

      await this.mapService
        .getGeocoding(preAddress)
        .toPromise()
        .then((data) => {
          if (data.status != 'OK') {
            this.errorModal.show(
              this.translateService.instant(
                'invalid_address_at_stop_point_number',
                { number: i }
              )
            );
            return;
          }
          preResult = data;
        });

      let stopPointValue = this.stopPoints.controls[i].value;
      let address = `${stopPointValue.country} ${stopPointValue.state} ${stopPointValue.city} ${stopPointValue.street}`;
      let result!: GeocodingLocation;
      await this.mapService
        .getGeocoding(address)
        .toPromise()
        .then((data) => {
          if (data.status != 'OK') {
            this.errorModal.show(
              this.translateService.instant(
                'invalid_address_at_stop_point_number',
                { number: i + 1 }
              )
            );
            return;
          }
          result = data;
        });

      let preLatLong: LatLong = {
        lat: preResult.results[0].geometry.location.lat,
        lng: preResult.results[0].geometry.location.lng,
      };

      let latLong: LatLong = {
        lat: result.results[0].geometry.location.lat,
        lng: result.results[0].geometry.location.lng,
      };

      await this.mapService
        .getETA(preLatLong, latLong)
        .toPromise()
        .then((data) => {
          let fromDateTime = new Date(this.stopPoints.controls[i - 1].value.fromDate);
          fromDateTime.setHours(this.stopPoints.controls[i - 1].value.fromHourTime);
          fromDateTime.setMinutes(this.stopPoints.controls[i - 1].value.fromMinuteTime);
          fromDateTime.setSeconds(
            fromDateTime.getSeconds() +
              data.rows[0].elements[0].duration.value +
              1200
          );
          let toDateTime = new Date(this.stopPoints.controls[i - 1].value.toDate);
          toDateTime.setHours(this.stopPoints.controls[i - 1].value.toHourTime);
          toDateTime.setMinutes(this.stopPoints.controls[i - 1].value.toMinuteTime);
          toDateTime.setSeconds(
            toDateTime.getSeconds() +
              data.rows[0].elements[0].duration.value +
              1200
          );
          let stopPoint = this.stopPoints.controls[i].value;

          this.stopPoints.controls[i].patchValue({
            fromDate: fromDateTime,
            fromHourTime: this.formatHourMinute(fromDateTime.getHours()),
            fromMinuteTime: this.formatHourMinute(fromDateTime.getMinutes()),
            toDate: toDateTime,
            toHourTime: this.formatHourMinute(toDateTime.getHours()),
            toMinuteTime: this.formatHourMinute(toDateTime.getMinutes()),
            postCode: stopPoint.postCode,
            country: stopPoint.country,
            state: stopPoint.state,
            city: stopPoint.city,
            street: stopPoint.street,
          });
        });
    }
  }

  checkETAValidate(): boolean {
    let firstValue = this.stopPoints.controls[0].value;
    if (firstValue.fromDate === undefined || firstValue.fromDate === '') {
      this.errorModal.show(
        this.translateService.instant(
          'please_select_start_point_date_and_try_again'
        )
      );
      return false;
    }
    if (firstValue.toDate === undefined || firstValue.toDate === '') {
      this.errorModal.show(
        this.translateService.instant(
          'please_select_start_point_date_and_try_again'
        )
      );
      return false;
    }
    if (
      firstValue.fromHourTime === undefined ||
      firstValue.fromMinuteTime === undefined ||
      firstValue.toHourTime === undefined ||
      firstValue.toMinuteTime === undefined
    ) {
      this.errorModal.show(
        this.translateService.instant(
          'please_select_start_point_time_and_try_again'
        )
      );
      return false;
    }
    let checker = true;
    this.stopPoints.controls.forEach((stopPoint) => {
      if (
        stopPoint.value.country == undefined ||
        stopPoint.value.country == ''
      ) {
        this.errorModal.show(
          this.translateService.instant(
            'please_complete_all_address_fields_to_continue_ETA'
          )
        );
        checker = false;
      }
      if (stopPoint.value.state == undefined || stopPoint.value.state == '') {
        this.errorModal.show(
          this.translateService.instant(
            'please_complete_all_address_fields_to_continue_ETA'
          )
        );
        checker = false;
      }
      if (stopPoint.value.city == undefined || stopPoint.value.city == '') {
        this.errorModal.show(
          this.translateService.instant(
            'please_complete_all_address_fields_to_continue_ETA'
          )
        );
        checker = false;
      }
      if (stopPoint.value.street == undefined || stopPoint.value.street == '') {
        this.errorModal.show(
          this.translateService.instant(
            'please_complete_all_address_fields_to_continue_ETA'
          )
        );
        checker = false;
      }
    });
    return checker;
  }

  createStopPoint(): FormGroup {
    return this.formBuilder.group({
      fromDate: new FormControl(''),
      fromHourTime: new FormControl(''),
      fromMinuteTime: new FormControl(''),
      toDate: new FormControl(''),
      toHourTime: new FormControl(''),
      toMinuteTime: new FormControl(''),
      postCode: new FormControl(''),
      country: new FormControl(''),
      state: new FormControl(''),
      city: new FormControl(''),
      street: new FormControl(''),
    });
  }

  initStopPointsForm() {
    this.updateScheduleItem.stopPoints.forEach((item) => {
      let itemFromDate = new Date();
      let itemToDate = new Date();
      if(item.fromDateTime != undefined){
        itemFromDate = new Date(item.fromDateTime);
      }
      if(item.toDateTime != undefined){
        itemToDate = new Date(item.toDateTime);
      }
      this.stopPoints.push(
        this.formBuilder.group({
          fromDate: new FormControl(itemFromDate || ''),
          fromHourTime: new FormControl(
            this.formatHourMinute(itemFromDate.getHours()) || '00'
          ),
          fromMinuteTime: new FormControl(
            this.formatHourMinute(itemFromDate.getMinutes()) || '00'
          ),
          toDate: new FormControl(itemToDate || ''),
          toHourTime: new FormControl(
            this.formatHourMinute(itemToDate.getHours()) || '00'
          ),
          toMinuteTime: new FormControl(
            this.formatHourMinute(itemToDate.getMinutes()) || '00'
          ),
          postCode: new FormControl(item.postCode || ''),
          country: new FormControl(item.country || ''),
          state: new FormControl(item.state || ''),
          city: new FormControl(item.city || ''),
          street: new FormControl(item.street || ''),
        })
      );
    });
  }

  getControls() {
    return (this.createScheduleForm.get('stopPoints') as FormArray).controls;
  }

  removeStop(index: number) {
    this.stopPoints = this.createScheduleForm.get('stopPoints') as FormArray;
    this.stopPoints.removeAt(index);
  }

  swapStop(index: number) {
    this.stopPoints = this.createScheduleForm.get('stopPoints') as FormArray;
    if (index >= 0) {
      const newStopPoints = this.swap(this.stopPoints.value, index, index + 1);
      this.stopPoints.setValue(newStopPoints);
    }
  }

  swap(arr: any[], index1: number, index2: number): any[] {
    arr = [...arr];
    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
    return arr;
  }
}
