<div bsModal #errorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="errorModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog  modal-lg create-modal modal-dialog-small">
    <div #modalContent class="modal-content" style="border-radius: 20px!important; background-color: #EBEBEB;">
         <!-- <div class="modal-header">
          <button type="button" class="close fixed-left-top-btn" data-dismiss="modal" (click)="close()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
        <div class="modal-body p-0 py-5">
              <div class="col text-center my-3">
                <mat-icon class="error-icon">
                  error_rounded
                </mat-icon>
                <div style=" margin-top: 40px; margin-bottom: 40px;">
                  <p ><strong>{{ 'error' | translate}}</strong></p>
                  <p >{{message}}</p>
                </div>
                <button type="button" data-dismiss="modal" class="btn btn-primary justify-content-center custom-btn-style-1" (click)="close()">
                  {{ 'dismiss' | translate }}
                </button>
              </div>
        </div>
    </div>
  </div>
</div>