<div>
  <div class="overlay-detail">
    <div class="overlay-detail-info d-flex">
      <p style="font-size: 0.9rem" class="ml-1"><b>{{number}}. </b></p>
      <div class="ml-1">
        <p class="mb-1" style="font-size: 0.9rem"><b>{{carNumber}}</b></p>
        <p class="mb-1" style="font-size: 0.7rem">{{driver}}</p>
        <p class="mb-1" style="font-size: 0.7rem">{{contactNumber}}</p>
      </div>
    </div>
    <div>
    <div class="d-flex align-items-center" style="font-size: 0.8rem; font-weight:500;">
      <mat-icon style="color: #8b51f5;">fmd_good</mat-icon>
      {{distance}}
    </div>
    </div>
  </div>
</div>